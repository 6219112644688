import { Spin } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

const VideoItem = ({ url, autoPlay, loop }) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <VideoWrapper>
      {!loaded && (
        <div className='spinner'>
          <Spin />
        </div>
      )}
      <Video
        preload='auto'
        onLoadedData={() => setLoaded(true)}
        controls={false}
        muted={true}
        autoPlay={autoPlay}
        loop={loop}>
        <source src={url} type='video/mp4' />
      </Video>
    </VideoWrapper>
  )
}

const VideoWrapper = styled.span`
  height: 100%;
  width: 100%;
  position: relative;
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
export default VideoItem

import { Input, Select, Checkbox, InputNumber } from 'formik-antd'

import instagram from '../../../../assets/images/instagram-color-square.svg'
import tiktok from '../../../../assets/images/tiktok-color-square.svg'
import youtube from '../../../../assets/images/youtube-color-square.svg'
import FormItem from '../../../utility/FormItem'
const socialIcons = { instagram, youtube, tiktok }
const { Option } = Select

export const CampaignDeliverables = ({ values, setValues }) => {
  const contentFormatOptions = [
    {
      // all channels
      label: 'Video',
      value: 'Video',
      channels: ['instagram', 'youtube', 'tiktok'],
    },
    {
      // youtube only
      label: 'Shorts',
      value: 'Shorts',
      channels: ['youtube'],
    },
    // intagram only
    {
      label: 'Story',
      value: 'Story',
      channels: ['instagram'],
    },
    {
      label: 'IGTV',
      value: 'IGTV',
      channels: ['instagram'],
    },
    {
      label: 'Reel',
      value: 'Reel',
      channels: ['instagram'],
    },
    {
      label: 'Post',
      value: 'Post',
      channels: ['instagram'],
    },
  ]

  return (
    <div className='section-wrapper'>
      {values.strategy !== 'ugc' && (
        <FormItem label='Content Formats' name='contentFormats'>
          {contentFormatOptions.map((format, i) => (
            <div
              key={i}
              className='content-format'
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginBottom: '10px',
              }}>
              <InputNumber
                value={values.contentFormatCounts?.[format.value.toLowerCase()] || null}
                placeholder='0'
                style={{ width: '75px' }}
                onChange={e => {
                  if (e > 0) {
                    setValues({
                      ...values,
                      contentFormats: values.contentFormats.includes(format.value)
                        ? values.contentFormats
                        : [...values.contentFormats, format.value],
                      contentFormatCounts: {
                        ...values.contentFormatCounts,
                        [format.value.toLowerCase()]: e,
                      },
                    })
                  } else {
                    setValues({
                      ...values,
                      contentFormats: values.contentFormats.filter(f => f !== format.value),
                      contentFormatCounts: Object.keys(values.contentFormatCounts)
                        .filter(key => key !== format.value.toLowerCase())
                        .reduce((obj, key) => {
                          obj[key] = values.contentFormatCounts[key]
                          return obj
                        }, {}),
                    })
                  }
                }}
              />
              {format.channels.map(channel => (
                <img
                  className={`social-icon ${
                    !values.socialChannels?.includes(channel) && 'not-available'
                  }`}
                  src={socialIcons[channel]}
                  alt={channel}
                  key={channel}
                />
              ))}
              {format.label}
            </div>
          ))}
        </FormItem>
      )}

      <FormItem label='Post Caption'>
        <Input.TextArea name='caption' autoSize={{ minRows: 4, maxRows: 10 }} />
      </FormItem>

      <FormItem label='Visuals / Theme'>
        <Input.TextArea name='visuals' autoSize={{ minRows: 4, maxRows: 10 }} />
      </FormItem>

      {values.strategy === 'giveaway' && (
        <FormItem label='Giveaway Guidelines / Prize Description'>
          <Input.TextArea name='giveawayDescription' autoSize={{ minRows: 4, maxRows: 10 }} />
        </FormItem>
      )}

      <FormItem label='Hashtags'>
        <Select style={{ width: '100%' }} mode='tags' name='hashtags'>
          {values?.hashtags?.map(hashtag => (
            <Option key={hashtag} value={hashtag}>
              {hashtag}
            </Option>
          ))}
        </Select>
      </FormItem>

      <FormItem label='Mentions'>
        <Select mode='tags' name='photoTags' style={{ width: '100%' }}>
          {values?.photoTags?.map(photoTag => (
            <Option key={photoTag} value={photoTag}>
              {photoTag}
            </Option>
          ))}
        </Select>
      </FormItem>

      <FormItem label='Story Hashtags'>
        <Select mode='tags' name='storyHashtags' style={{ width: '100%' }}>
          {values?.storyHashtags?.map(storyHashtag => (
            <Option key={storyHashtag} value={storyHashtag}>
              {storyHashtag}
            </Option>
          ))}
        </Select>
      </FormItem>

      <FormItem label='Story Mentions'>
        <Select mode='tags' name='storyMentions' style={{ width: '100%' }}>
          {values?.storyMentions?.map(storyMention => (
            <Option key={storyMention} value={storyMention}>
              {storyMention}
            </Option>
          ))}
        </Select>
      </FormItem>

      <Checkbox name='onlyStories'>Stories only</Checkbox>
    </div>
  )
}

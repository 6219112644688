import { Result } from 'antd'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import Brand from './brand/Brand'
import Brands from './brand/Brands'
import Campaign from './campaign/Campaign'
import CampaignsList from './campaign/CampaignsList'
import { Home } from './Home'
import OptIn from './opt-in/OptIn'
import OptInsList from './opt-in/OptInsList'
import UserPage from './user/UserPage'
import UsersList from './user/UsersList'
import Utility from './Utility'
import { UserContext } from '../../../contexts/UserContext'
import { routes } from '../../../routes'
import { LoadingLogo } from '../../general/LoadingLogo'

const Dashboard = () => {
  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData, status: userStatus } = useQuery('current-user', fetchCurrentUser)

  return (
    <Wrapper>
      {userData &&
        userStatus === 'success' &&
        (userData.role === 'administrator' ? (
          <>
            <aside className='sidebar'>
              {routes.map((route, i) => (
                <Link key={i} exact={route.exact} to={route.path} className={route.className}>
                  {route.icon} {route.label}
                </Link>
              ))}
            </aside>
            <div className='dashboard'>
              <Switch>
                <Route exact path='/payments'>
                  <Home />
                </Route>
                <Route path='/campaigns'>
                  <CampaignsList />
                </Route>
                <Route path='/campaign/:id'>
                  <Campaign />
                </Route>
                <Route path='/opt-ins'>
                  <OptInsList />
                </Route>
                <Route path='/opt-in/:id'>
                  <OptIn />
                </Route>
                <Route path='/brands'>
                  <Brands />
                </Route>
                <Route path='/brand/:id'>
                  <Brand />
                </Route>
                <Route path='/users'>
                  <UsersList />
                </Route>
                <Route path='/user/:id'>
                  <UserPage />
                </Route>
                <Route path='/utility'>
                  <Utility />
                </Route>
                <Route path='/'>
                  <Redirect to='/campaigns' />
                </Route>
              </Switch>
            </div>
          </>
        ) : (
          <div className='no-access'>
            <Result
              status='403'
              title='403'
              subTitle='Sorry, you are not authorized to access this dashboard.'
            />
          </div>
        ))}
      {(userStatus === 'loading' || !userData) && (
        <div className='loading-page'>
          <LoadingLogo />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  font-size: 1rem;
  max-width: 100vw;

  .sidebar {
    display: none;
    width: fit-content;
    @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
      background: #fff;
      display: flex;
      flex-direction: column;
      padding: 20px 20px 20px 0;
      border-right: 1px solid #e6e6e6;
      height: calc(100vh - 50px);
      position: sticky;
      top: 50px;
      .sub-links {
        display: flex;
        flex-direction: column;
        gap: 5px;
        max-height: 0;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s ease-in-out;
        margin: 0 0 0 20px;
        &.expand {
          max-height: 300px;
          visibility: visible;
          opacity: 1;
          margin: 5px 0 10px 20px;
        }
        .sub-link {
          color: #444;
          &.selected {
            color: ${props => props.theme.crcoTechBlue};
          }
        }
      }
    }
    @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
      padding: 20px 40px 20px 0;
      .sub-links {
        margin: 0 0 0 50px;
        &.expand {
          margin: 5px 0 10px 50px;
        }
      }
    }
  }

  .dashboard {
    background: #fff;
    flex: 1;
    min-width: 0; // allows max-width to work with overflow: clip
    max-width: 100%;
    overflow: clip; // allows sticky header to work
  }
  .no-access,
  .loading-page {
    background: #f4f4f8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
`

const Link = styled(NavLink)`
  color: ${props => props.theme.crcoMidnight};
  padding: 10px 20px;
  margin: 5px 0;
  font-size: 1rem;
  border-radius: 0 20px 20px 0;
  transition: 0.2s ease-in-out;
  &.logout {
    border-top: 1px solid #e6e6e6;
    padding-top: 20px;
    border-radius: 0;
  }
  &.active {
    background: #ecf6ff;
    color: ${props => props.theme.crcoTechBlue};
  }
  &:hover:not(.active) {
    background: #f2f2f2;
    color: ${props => props.theme.crcoMidnight};
    &.logout {
      background: #fff;
      color: #ff0000;
    }
  }
  .anticon {
    margin-right: 10px;
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    padding: 10px 40px;
  }
`

export default Dashboard

import {
  CaretDownFilled,
  CaretUpFilled,
  DownloadOutlined,
  FilterOutlined,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Button, Drawer, Empty, message, Modal, notification, Spin, Tag, Tooltip } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input, Select } from 'formik-antd'
import { debounce, isEqual } from 'lodash'
import moment from 'moment'
import React, { useEffect, useContext, useMemo, useRef, useState } from 'react'
import { useQuery, useInfiniteQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { UserFilters } from './UserFilters'
import instagram from '../../../../assets/images/instagram-color-square.svg'
import tiktok from '../../../../assets/images/tiktok-color-square.svg'
import youtube from '../../../../assets/images/youtube-color-square.svg'
import { API_URL } from '../../../../constants'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import FormItem from '../../../utility/FormItem'
import { Wrapper } from '../campaign/CampaignsList'

const socialIcons = { instagram, tiktok, youtube }

const isEqualExcludingSearch = (old, next) =>
  isEqual({ ...old, search: undefined }, { ...next, search: undefined })

const UsersList = () => {
  // #region Constants
  const history = useHistory()
  const { getCategories } = useContext(GlobalContext)
  const [users, setUsers] = useState([])
  const [countries, setCountries] = useState([])
  const [currentSortItem, setCurrentSortItem] = useState('registered-desc')
  const [formData, setFormData] = useState({ sort: currentSortItem })
  const [exportLoading, setExportLoading] = useState(false)
  const [confirmExport, setConfirmExport] = useState(false)
  const [newUserVisible, setNewUserVisible] = useState(false)
  const [filterVisible, setFilterVisible] = useState(false)
  const scrollRef = useRef()
  const platforms = ['instagram', 'tiktok', 'youtube']
  // #endregion Constants

  // #region Queries
  const fetchUsers = async ({ pageParam = 0 }) => {
    const { data } = await axios.get(`${API_URL}/users`, {
      params: {
        ...formData,
        page: pageParam,
      },
      paramsSerializer: {
        indexes: true, // include array indexes in query string to parse in backend
      },
    })
    return data
  }

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } = useInfiniteQuery(
    ['users', formData],
    fetchUsers,
    {
      getNextPageParam: lastPage => lastPage.nextCursor,
    }
  )

  const { data: categories } = useQuery('categories', getCategories)
  // #endregion Queries

  // #region Effects
  useEffect(() => {
    const array = data?.pages.flatMap(page => page.users) || []
    setUsers(array)
  }, [data, status])

  useEffect(() => {
    axios
      .get('../json/countries.json')
      .then(res => {
        setCountries(res.data.countries)
      })
      .catch(() => {
        setCountries([])
      })
  }, [])
  // #endregion Effects

  // #region Functions
  const sortBy = sortItem => {
    const updatedSortItem =
      currentSortItem === `${sortItem}-desc` ? `${sortItem}-asc` : `${sortItem}-desc`
    setCurrentSortItem(updatedSortItem)
    setFormData({ ...formData, sort: updatedSortItem })
  }

  const handleSearch = useMemo(
    () =>
      debounce(async data => {
        const oldLen = formData?.search?.length || 0
        const newLen = data?.search?.length || 0
        if (!isEqualExcludingSearch(formData, data) || newLen >= 3 || newLen < oldLen)
          setFormData(data)
      }, 500),
    []
  )

  const handleExport = async () => {
    try {
      setConfirmExport(false)
      setExportLoading(true)

      await axios.get(`${API_URL}/users/export`, {
        params: formData,
        paramsSerializer: {
          indexes: true, // include array indexes in query string to parse in backend
        },
      })

      notification.info({
        message: 'Export Started',
        description: 'You will receive an email when your export is ready to download.',
        duration: 0,
      })
    } catch (error) {
      message.error('Error exporting users')
    } finally {
      setExportLoading(false)
    }
  }

  const handleScroll = () => {
    const { scrollTop, offsetHeight, scrollHeight } = scrollRef.current
    const actualDistance = scrollHeight - (scrollTop + offsetHeight)
    if (actualDistance < 400 && !isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }
  // #endregion Functions

  return (
    <Wrapper>
      <Formik onSubmit={handleSearch} initialValues={formData}>
        {({ submitForm }) => (
          <Form>
            <div className='header-wrapper'>
              <h1>Users</h1>
              {status === 'success' ? (
                <span className='num-results'>
                  {users?.length} / {data?.pages[0]?.totalResults?.toLocaleString() || 0} results
                </span>
              ) : (
                <LoadingOutlined spin />
              )}
              <div className='header-buttons'>
                <Input
                  name='search'
                  placeholder='Search users'
                  prefix={<SearchOutlined />}
                  onChange={submitForm}
                  allowClear
                  style={{ width: 'auto' }}
                />
                <Button
                  type='primary'
                  icon={<FilterOutlined />}
                  onClick={() => setFilterVisible(true)}>
                  Filters
                </Button>
                <Button
                  onClick={() => setConfirmExport(true)}
                  type='secondary'
                  loading={exportLoading}
                  icon={<DownloadOutlined />}>
                  Export
                </Button>
                <Button
                  type='secondary'
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setNewUserVisible(true)
                  }}>
                  New User
                </Button>
              </div>
            </div>

            <Drawer
              title='Filter Users'
              open={filterVisible}
              onClose={() => setFilterVisible(false)}>
              <UserFilters submitForm={submitForm} countries={countries} niches={categories} />
            </Drawer>
          </Form>
        )}
      </Formik>

      <Modal
        title='User Export'
        open={confirmExport}
        okText='Begin Export'
        onOk={handleExport}
        onCancel={() => setConfirmExport(false)}>
        <p>
          Are you sure you want to export {data?.pages[0]?.totalResults?.toLocaleString()} users?
          You will receive an email to download the CSV file when it&apos;s ready.
        </p>
      </Modal>

      <Modal
        title='New User'
        destroyOnClose
        footer={null}
        onCancel={() => setNewUserVisible(false)}
        open={newUserVisible}>
        <Formik
          onSubmit={(data, { setSubmitting }) => {
            axios
              .post(`${API_URL}/admin/user`, data)
              .then(res => {
                history.push(`/user/${res.data}`)
              })
              .catch(err => {
                message.error(
                  err.response?.data?.err || 'Something went wrong while creating new user'
                )
              })
              .finally(() => {
                setSubmitting(false)
              })
          }}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            role: '',
          }}
          validationSchema={yup.object().shape({
            firstName: yup.string().required('Required'),
            lastName: yup.string().required('Required'),
            email: yup.string().email('Invalid email').required('Required'),
            role: yup.string().required('Required'),
          })}>
          {({ isSubmitting }) => (
            <Form>
              <FormItem label='First Name' name='firstName'>
                <Input name='firstName' />
              </FormItem>
              <FormItem label='Last Name' name='lastName'>
                <Input name='lastName' />
              </FormItem>
              <FormItem label='Email' name='email'>
                <Input name='email' />
              </FormItem>
              <FormItem label='Role' name='role'>
                <Select name='role' placeholder='Select' style={{ width: '100%' }}>
                  <Select.Option value='creator'>Creator</Select.Option>
                  <Select.Option value='brand'>Brand</Select.Option>
                  <Select.Option value='administrator'>Administrator</Select.Option>
                </Select>
              </FormItem>
              <Button loading={isSubmitting} htmlType='submit' type='primary'>
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>

      <div className='list-inner' onScroll={handleScroll} ref={scrollRef}>
        <div className='list-header users'>
          <div
            className='id sortable'
            onClick={() => sortBy('id')}
            onKeyDown={() => sortBy('id')}
            tabIndex={0}
            role='button'
            aria-label='Sort by ID'
            aria-describedby='id-sort'>
            ID
            <div className='sort-arrows'>
              <span className={`sort-arrow ${currentSortItem === 'id-desc' && 'current'}`}>
                <CaretUpFilled />
              </span>
              <span className={`sort-arrow ${currentSortItem === 'id-asc' && 'current'}`}>
                <CaretDownFilled />
              </span>
            </div>
          </div>
          <div className='user'>Name</div>
          <div
            className='email sortable'
            onClick={() => sortBy('email')}
            onKeyDown={() => sortBy('email')}
            tabIndex={0}
            role='button'
            aria-label='Sort by Email'
            aria-describedby='email-sort'>
            Email
            <div className='sort-arrows'>
              <span className={`sort-arrow ${currentSortItem === 'email-desc' && 'current'}`}>
                <CaretUpFilled />
              </span>
              <span className={`sort-arrow ${currentSortItem === 'email-asc' && 'current'}`}>
                <CaretDownFilled />
              </span>
            </div>
          </div>
          <div className='role'>Role</div>
          <div className='country'>Country</div>
          <div
            className='date sortable'
            onClick={() => sortBy('registered')}
            onKeyDown={() => sortBy('registered')}
            tabIndex={0}
            role='button'
            aria-label='Sort by Registered'
            aria-describedby='registered-sort'>
            Registered
            <div className='sort-arrows'>
              <span className={`sort-arrow ${currentSortItem === 'registered-desc' && 'current'}`}>
                <CaretUpFilled />
              </span>
              <span className={`sort-arrow ${currentSortItem === 'registered-asc' && 'current'}`}>
                <CaretDownFilled />
              </span>
            </div>
          </div>
          <div
            className='date sortable'
            onClick={() => sortBy('lastLoginDate')}
            onKeyDown={() => sortBy('lastLoginDate')}
            tabIndex={0}
            role='button'
            aria-label='Sort by Last Login'
            aria-describedby='lastLogin-sort'>
            Last Login
            <div className='sort-arrows'>
              <span
                className={`sort-arrow ${currentSortItem === 'lastLoginDate-desc' && 'current'}`}>
                <CaretUpFilled />
              </span>
              <span
                className={`sort-arrow ${currentSortItem === 'lastLoginDate-asc' && 'current'}`}>
                <CaretDownFilled />
              </span>
            </div>
          </div>
          <div className='date'>Last Activity</div>
          <div className='socials'>Socials</div>
          <div className='niche'>Niche</div>
          <div className='creator-profile'>Creator Profile</div>
        </div>
        {status === 'success' &&
          (users?.length ? (
            <>
              {users.map(user => {
                const { lastActivityDate } = user.extraData

                const connectedPlatforms = platforms.filter(platform =>
                  user.socialProfiles.find(profile => profile.platform === platform)
                )

                const nicheIds = user.creatorProfile?.niches?.map(niche => niche.categoryId)
                const userNiches = categories?.filter(niche => nicheIds?.includes(niche.id))
                const firstNiche = userNiches?.[0]

                return (
                  <Link to={`/user/${user.id}`} key={user.id} className='list-item users'>
                    <div className='id'>#{user.id}</div>
                    <div className='user'>
                      {user.firstName} {user.lastName}
                    </div>
                    <div className='email'>{user.email}</div>
                    <div className='role'>
                      {user.role === 'administrator' ? 'Admin' : user.role}
                    </div>
                    <div className={`country ${!user.creatorProfile?.shippingCountry && 'none'}`}>
                      {user.creatorProfile?.shippingCountry
                        ? countries.find(
                            country => country.country_code === user.creatorProfile?.shippingCountry
                          )?.name
                        : 'Unknown'}
                    </div>
                    <div className='date'>{moment(user.registered).format('ll')}</div>
                    <div className={`date ${!user.lastLoginDate && 'none'}`}>
                      {user.lastLoginDate ? moment(user.lastLoginDate).format('ll') : 'None'}
                    </div>
                    <div className={`date ${!lastActivityDate && 'none'}`}>
                      {lastActivityDate ? moment(lastActivityDate).format('ll') : 'None'}
                    </div>
                    <div className={`socials ${!connectedPlatforms.length && 'none'}`}>
                      {connectedPlatforms?.length
                        ? connectedPlatforms.map((platform, i) => {
                            const socialProfile = user.socialProfiles.find(
                              profile => profile.platform === platform
                            )
                            return (
                              <Tooltip title={`@${socialProfile.username}`} key={i}>
                                <img
                                  className='social-icon'
                                  src={socialIcons[platform]}
                                  alt={platform}
                                />
                              </Tooltip>
                            )
                          })
                        : 'None'}
                    </div>
                    <div className={`niche ${!userNiches?.length && 'none'}`}>
                      {userNiches?.length ? (
                        <>
                          <Tag key={firstNiche.id} color='blue'>
                            {firstNiche.title}
                          </Tag>
                          {userNiches.length > 1 && (
                            <Tooltip title={userNiches.map(niche => niche.title).join(' • ')}>
                              <Tag>+{userNiches.length - 1} more</Tag>
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        'Unknown'
                      )}
                    </div>
                    <div
                      className={`creator-profile ${!user.creatorProfile?.profileSlug && 'none'}`}>
                      {user.creatorProfile?.profileSlug ? (
                        <a
                          href={`https://${user.creatorProfile?.profileSlug}.creator.co`}
                          target='_blank'
                          rel='noopener noreferrer'
                          onClick={e => e.stopPropagation()}>
                          {user.creatorProfile?.profileSlug}
                        </a>
                      ) : (
                        'None'
                      )}
                    </div>
                  </Link>
                )
              })}
              {isFetchingNextPage && (
                <div className='loading'>
                  <Spin />
                </div>
              )}
            </>
          ) : (
            <div className='no-results'>
              <Empty description='No users found.' />
            </div>
          ))}

        {status === 'loading' && (
          <div className='loading'>
            <Spin />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default UsersList

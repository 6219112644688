import { Alert } from 'antd'
import { InputNumber } from 'formik-antd'
import numeral from 'numeral'

import FormItem from '../../../utility/FormItem'

export const CampaignMetrics = ({ campaign }) => {
  return (
    <>
      <div className='section-wrapper'>
        <h3>KPIs</h3>
        <Alert
          message='These metrics are only visible to admins at this time.'
          type='info'
          showIcon
          style={{ marginBottom: '20px' }}
        />
        <FormItem name='collabsPerMth' label='Collaborations per month'>
          <InputNumber name='collabsPerMth' placeholder='0' style={{ width: '150px' }} />
        </FormItem>
        <FormItem name='contentCountGoal' label='Pieces of content per creator'>
          <InputNumber name='contentCountGoal' placeholder='0' style={{ width: '150px' }} />
        </FormItem>
        {campaign.strategy !== 'ugc' && (
          <>
            <FormItem name='totalReachGoal' label='Total reach'>
              <InputNumber
                name='totalReachGoal'
                placeholder='0'
                formatter={value => (value > 0 ? numeral(value).format('0,0') : '')}
                style={{ width: '150px' }}
              />
            </FormItem>
            <FormItem name='totalImpressionsGoal' label='Total impressions'>
              <InputNumber
                name='totalImpressionsGoal'
                placeholder='0'
                formatter={value => (value > 0 ? numeral(value).format('0,0') : '')}
                style={{ width: '150px' }}
              />
            </FormItem>
            <FormItem name='avgEngagementGoal' label='Average engagement rate'>
              <InputNumber
                name='avgEngagementGoal'
                formatter={value => `${value || 0}%`}
                placeholder='0'
                style={{ width: '150px' }}
              />
            </FormItem>
            {campaign.socialChannels?.includes('youtube') && (
              <FormItem name='avgViewsPerMth' label='Average views per month'>
                <InputNumber
                  name='avgViewsPerMth'
                  placeholder='0'
                  formatter={value => (value > 0 ? numeral(value).format('0,0') : '')}
                  style={{ width: '150px' }}
                />
              </FormItem>
            )}
          </>
        )}
        <FormItem name='totalBudget' label='Total budget allocation'>
          <InputNumber
            name='totalBudget'
            style={{ width: '150px' }}
            placeholder='$0'
            formatter={value =>
              value > 0 ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
            }
          />
        </FormItem>
      </div>

      <div className='section-wrapper'>
        <h3>ROI Tracking</h3>
        <FormItem name='likeValue' label='Value per like'>
          <InputNumber
            name='likeValue'
            style={{ width: '150px' }}
            placeholder='$0.10/like'
            formatter={value =>
              value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
            }
          />
        </FormItem>
        <FormItem name='commentValue' label='Value per comment'>
          <InputNumber
            name='commentValue'
            style={{ width: '150px' }}
            placeholder='$1.00/comment'
            formatter={value =>
              value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
            }
          />
        </FormItem>
        <FormItem name='viewValue' label='Value per view'>
          <InputNumber
            name='viewValue'
            style={{ width: '150px' }}
            placeholder='$0.05/comment'
            formatter={value =>
              value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
            }
          />
        </FormItem>
        <FormItem name='saveValue' label='Value per save'>
          <InputNumber
            name='saveValue'
            style={{ width: '150px' }}
            placeholder='$5.00/save'
            formatter={value =>
              value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
            }
          />
        </FormItem>
        <FormItem name='shareValue' label='Value per share'>
          <InputNumber
            name='shareValue'
            style={{ width: '150px' }}
            placeholder='$5.00/share'
            formatter={value =>
              value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
            }
          />
        </FormItem>
        <FormItem name='reachValue' label='Value per reach'>
          <InputNumber
            name='reachValue'
            style={{ width: '150px' }}
            placeholder='$0.05/reach'
            formatter={value =>
              value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
            }
          />
        </FormItem>
        <FormItem name='impressionValue' label='Value per impression'>
          <InputNumber
            name='impressionValue'
            style={{ width: '150px' }}
            placeholder='$0.05/impression'
            formatter={value =>
              value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
            }
          />
        </FormItem>
      </div>
    </>
  )
}

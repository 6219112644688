import { PictureOutlined } from '@ant-design/icons'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import affiliate from '../../../../assets/images/affiliate.svg'
import bespoke from '../../../../assets/images/bespoke.svg'
import giveaway from '../../../../assets/images/giveaway.svg'
import instagram from '../../../../assets/images/instagram-color-square.svg'
import shoutout from '../../../../assets/images/shoutout.svg'
import tiktok from '../../../../assets/images/tiktok-color-square.svg'
import ugc from '../../../../assets/images/ugc.svg'
import youtube from '../../../../assets/images/youtube-color-square.svg'

const socialIcons = { instagram, tiktok, youtube }

const CampaignsListItem = ({ campaign, setWeeks }) => {
  const [optInData, setOptInData] = useState({})
  const [strategyImg, setStrategyImg] = useState(false)

  useEffect(() => {
    // optin count
    const count = campaign.optIns.length
    let week1 = 0
    let week2 = 0
    let week3 = 0

    // get the last 3 mondays
    let date = new Date()
    let currentDate = date.getDate()
    let currentDay = date.getDay()
    let mon1 = new Date()
    let sun1 = new Date()
    let mon2 = new Date()
    let sun2 = new Date()
    let mon3 = new Date()
    let sun3 = new Date()
    if (currentDay === 0) {
      mon1.setDate(currentDate - 6)
    } else {
      mon1.setDate(currentDate - (currentDay - 1))
    }
    mon1.setHours(0, 0, 0, 0)
    sun1.setDate(mon1.getDate() + 6)
    mon2.setDate(mon1.getDate() - 7)
    mon2.setHours(0, 0, 0, 0)
    sun2.setDate(mon2.getDate() + 6)
    mon3.setDate(mon2.getDate() - 7)
    mon3.setHours(0, 0, 0, 0)
    sun3.setDate(mon3.getDate() + 6)

    campaign.optIns.forEach(optIn => {
      let createdDate = new Date(optIn.created)
      if (createdDate >= mon1) {
        week1++
      } else if (createdDate >= mon2) {
        week2++
      } else if (createdDate >= mon3) {
        week3++
      }
    })

    setWeeks([
      { mon: moment(mon1).format('ll'), sun: moment(sun1).format('ll') },
      { mon: moment(mon2).format('ll'), sun: moment(sun2).format('ll') },
      { mon: moment(mon3).format('ll'), sun: moment(sun3).format('ll') },
    ])

    setOptInData({
      count,
      week1,
      week2,
      week3,
    })

    switch (campaign.strategy) {
      case 'ugc':
        setStrategyImg(ugc)
        break
      case 'giveaway':
        setStrategyImg(giveaway)
        break
      case 'affiliate':
        setStrategyImg(affiliate)
        break
      case 'bespoke':
        setStrategyImg(bespoke)
        break
      default:
        setStrategyImg(shoutout)
        break
    }
  }, [campaign, setWeeks])

  return (
    <Link to={`/campaign/${campaign.id}`} key={campaign.id} className='list-item'>
      <div className='thumbnail'>
        {campaign.thumbnailSrc ? <img src={campaign.thumbnailSrc} alt='' /> : <PictureOutlined />}
      </div>
      <div className='campaign'>{campaign.title}</div>
      <div className='brand'>{campaign.brand.name}</div>
      <div className={`status ${campaign.status}`}>
        {campaign.status === 'publish' ? 'Active' : campaign.status}
      </div>
      <div className={`channel ${!campaign.socialChannels?.[0]?.length && 'none'}`}>
        {campaign.socialChannels?.[0]?.length
          ? campaign.socialChannels.map((channel, i) => (
              <img src={socialIcons[channel]} alt={channel} key={i} />
            ))
          : 'None'}
      </div>
      <div className='strategy'>
        <img src={strategyImg} alt='strategy' />
      </div>
      <div className='date'>{moment(campaign.date).format('ll')}</div>
      <div className={`date ${!campaign.publishDate && 'none'}`}>
        {campaign.publishDate ? moment(campaign.publishDate).format('ll') : 'Not Published'}
      </div>
      <div className={`opt-ins ${!optInData.week1 && 'none'}`}>{optInData.week1}</div>
      <div className={`opt-ins ${!optInData.week2 && 'none'}`}>{optInData.week2}</div>
      <div className={`opt-ins ${!optInData.week3 && 'none'}`}>{optInData.week3}</div>
    </Link>
  )
}

export default CampaignsListItem

import { Button, Empty, Radio, Spin } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { CreateEditStep } from './CreateEditStep'
import { Wrapper } from './CustomStepList'
import { API_URL } from '../../../../../constants'

const StepList = ({ close, addStep, brandId }) => {
  const [type, setType] = useState('programmed')
  const [copyCustomStepOpen, setCopyCustomStepOpen] = useState(false)

  const { data, status } = useQuery('steps', async () => {
    const { data } = await axios.get(`${API_URL}/brand/${brandId}/campaign/steps`)
    return data
      .filter(step => step.slug !== 'content-preview') // Remove content preview step (no functionality yet)
      .map(step => ({ ...step, title: step.title.replace('Old: ', '') })) // Clean up step titles, "Old" steps will eventually be replaced but still in use for now
  })

  const handleCreateSubmit = values => {
    const {
      brandStatus,
      influencerStatus,
      brandCompletedStatus,
      influencerCompletedStatus,
      customForm,
      ...customStep
    } = values

    addStep({
      ...customStep,
      customForm,
      stepSettings: {
        brandStatus,
        influencerStatus,
        brandCompletedStatus,
        influencerCompletedStatus,
      },
    })
    close()
  }

  return (
    <Wrapper>
      <div className='step-type-options'>
        <Radio checked={type === 'programmed'} onChange={() => setType('programmed')}>
          Pre-Programmed
        </Radio>
        <Radio checked={type === 'custom'} onChange={() => setType('custom')}>
          Custom
        </Radio>
      </div>

      {type === 'programmed' ? (
        <>
          {status === 'success' && (
            <Formik
              onSubmit={values => {
                if (type === 'programmed') {
                  const step = JSON.parse(values.step)
                  addStep({ stepId: step.id })
                  close()
                }
              }}
              initialValues={{}}>
              {({ isSubmitting, values, setValues }) => (
                <Form>
                  <div className='steps-list'>
                    {data.length ? (
                      data.map(step => (
                        <Radio
                          name='step'
                          className='step'
                          key={step.id}
                          value={JSON.stringify(step)}
                          checked={values.step === JSON.stringify(step)}
                          onChange={() => {
                            setValues({ step: JSON.stringify(step) })
                          }}>
                          <p className='title'>{step.title}</p>
                          <p className='description'>{step.description}</p>
                        </Radio>
                      ))
                    ) : (
                      <Empty description='No steps found.' image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                  {!!data.length && (
                    <Button
                      disabled={!values.step}
                      loading={isSubmitting}
                      htmlType='submit'
                      type='primary'>
                      Save
                    </Button>
                  )}
                </Form>
              )}
            </Formik>
          )}
          {status === 'loading' && (
            <div className='loading'>
              <Spin />
            </div>
          )}
        </>
      ) : (
        <CreateEditStep
          handleCreateSubmit={handleCreateSubmit}
          setCopyCustomStepOpen={setCopyCustomStepOpen}
          copyCustomStepOpen={copyCustomStepOpen}
        />
      )}
    </Wrapper>
  )
}

export default StepList

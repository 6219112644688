import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { Field, ErrorMessage } from 'formik'
import React from 'react'
import styled from 'styled-components'

const FormItem = ({ children, name, label, sublabel, subtext, size, info = '' }) => {
  return (
    <Wrapper size={size}>
      <Field>
        {({ form }) => {
          const hasError = form.errors[name] && form.touched[name]
          return (
            <div>
              <label className='item-label' style={{ color: !hasError || 'red' }}>
                {info && (
                  <Tooltip placement='topLeft' title={info}>
                    <span className='info'>
                      <QuestionCircleOutlined />{' '}
                    </span>
                  </Tooltip>
                )}
                {label && <span>{label} </span>}
                {sublabel && <span className='sublabel'>{sublabel} </span>}
              </label>
              {subtext && <p className='subtext'>{subtext}</p>}
              {children}
            </div>
          )
        }}
      </Field>
      <div style={{ margin: '4px 0 12px' }}>
        {name && (
          <ErrorMessage
            name={name}
            component='div'
            render={msg => (
              <div
                style={{ color: 'red', display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}>
                <ExclamationCircleOutlined style={{ marginRight: '8px', marginBottom: '3px' }} />
                <span>{msg}</span>
              </div>
            )}
          />
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: ${props => (props.size === 'small' ? '0px' : '20px')};
  .item-label {
    font-size: 16px;
    display: block;

    .info {
      color: #027df0;
    }

    .sublabel {
      font-size: 13px;
      color: #828282;
    }
  }
  .subtext {
    color: #666;
    font-family: 'Campton-Medium';
    font-size: 14px;
  }

  .ant-input,
  .ant-input-number,
  .ant-select .ant-select-selector {
    color: #23262f;

    &.ant-input-disabled {
      color: gray;
      cursor: auto;
      resize: none;
    }
    &:focus {
      border-color: #5ebeff;
    }
  }
  .ant-input-number {
    display: block;
    width: 100%;
    max-width: 200px;
  }
  .ant-slider {
    margin: 12px 24px 40px;

    .ant-slider-track {
      background-color: #18d96e;
    }
  }
`

export default FormItem

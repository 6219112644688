import { SearchOutlined } from '@ant-design/icons'
import { Button, Empty, Spin } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input, Radio } from 'formik-antd'
import React, { useRef, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components'

import { API_URL } from '../../../../../constants'

const CustomStepList = ({ setValues, close }) => {
  const [filters, setFilters] = useState({})

  const searchRef = useRef(0)

  const { data, status } = useInfiniteQuery(
    ['custom-steps', filters],
    async ({ pageParam = 1 }) => {
      const { data } = await axios.get(`${API_URL}/admin-dashboard/custom-steps/${pageParam}`, {
        params: filters,
      })
      return data
    }
  )
  return (
    <Wrapper>
      <Formik
        initialValues={{}}
        onSubmit={values => {
          searchRef.current++
          const currentRequest = searchRef.current
          setTimeout(() => {
            if (currentRequest === searchRef.current) {
              setFilters(prev => ({ ...prev, search: values.search }))
            }
          }, 300)
        }}>
        {({ handleSubmit }) => (
          <Form>
            <Input
              name='search'
              prefix={<SearchOutlined />}
              placeholder='Search'
              onChange={handleSubmit}
              type='text'
              allowClear
            />
          </Form>
        )}
      </Formik>
      {status === 'success' && (
        <Formik
          onSubmit={(values, { setSubmitting }) => {
            const step = JSON.parse(values.step)
            setValues(prev => ({
              ...prev,
              customStepTitle: step.customStepTitle,
              actionee: step.actionee,
              instructions: step.instructions,
              brandStatus: step.stepSettings.brandStatus,
              influencerStatus: step.stepSettings.influencerStatus,
              brandCompletedStatus: step.stepSettings.brandCompletedStatus,
              influencerCompletedStatus: step.stepSettings.influencerCompletedStatus,
            }))
            setSubmitting(false)
            close()
          }}
          initialValues={{}}>
          {({ isSubmitting, values }) => (
            <Form>
              <div className='steps-list'>
                {data.pages.map((page, i) =>
                  i || page.steps.length ? (
                    page.steps.map(step => (
                      <Radio
                        name='step'
                        className='step'
                        key={step.id}
                        value={JSON.stringify(step)}
                        checked={values.step === JSON.stringify(step)}
                        required>
                        <p className='title'>{step.customStepTitle}</p>
                        <p className='description'>{step.instructions}</p>
                      </Radio>
                    ))
                  ) : (
                    <Empty
                      key={i}
                      description='No steps found.'
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  )
                )}
              </div>
              {!!data.pages[0].steps.length && (
                <Button
                  disabled={!values.step}
                  loading={isSubmitting}
                  htmlType='submit'
                  type='primary'>
                  Select
                </Button>
              )}
            </Form>
          )}
        </Formik>
      )}
      {status === 'loading' && (
        <div className='loading'>
          <Spin />
        </div>
      )}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .steps-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 10px;
    max-height: 400px;
    overflow: auto;
    margin-bottom: 20px;
  }
  .step {
    width: 100%;
    transition: 0.2s ease-in-out;
    padding: 10px;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover,
    &:checked {
      background: ${props => props.theme.crcoPaleBlue};
    }
    p {
      margin: 0;
    }
    .description {
      color: ${props => props.theme.crcoGrey};
    }
  }
  .loading {
    display: grid;
    place-items: center;
    padding: 20px;
  }
`

export default CustomStepList

import { DatePicker, Select, Checkbox } from 'formik-antd'
import styled from 'styled-components'

import FormItem from '../../../utility/FormItem'
const { Option } = Select

export const UserFilters = ({ submitForm, countries, niches }) => {
  return (
    <Wrapper>
      <FormItem label='Required Fields'>
        <Select
          getPopupContainer={trigger => trigger.parentNode}
          virtual={false}
          name='requiredFields'
          mode='multiple'
          onChange={submitForm}
          allowClear
          placeholder='Select'>
          <Option value='email'>Email</Option>
          <Option value='firstName'>First Name</Option>
          <Option value='lastName'>Last Name</Option>
          <Option value='country'>Country</Option>
          <Option value='birthDate'>Birth Date</Option>
        </Select>
      </FormItem>

      <FormItem label='Role'>
        <Select
          getPopupContainer={trigger => trigger.parentNode}
          virtual={false}
          name='role'
          onChange={submitForm}
          allowClear
          placeholder='Select'>
          <Option value='brand'>Brand</Option>
          <Option value='creator'>Creator</Option>
          <Option value='administrator'>Admin</Option>
        </Select>
      </FormItem>

      <FormItem label='Registered'>
        <div className='filter-row'>
          <DatePicker
            onChange={submitForm}
            name='registeredFrom'
            placeholder='From Date'
            format='MMMM D, Y'
          />
          <DatePicker onChange={submitForm} name='registeredTo' placeholder='To Date' />
        </div>
      </FormItem>

      <FormItem label='Latest Login'>
        <DatePicker
          onChange={submitForm}
          name='latestLoginDate'
          placeholder='Select Date'
          format='MMMM D, Y'
        />
      </FormItem>

      <h3>Creators Only</h3>

      <FormItem label='Country'>
        <Select
          getPopupContainer={trigger => trigger.parentNode}
          virtual={false}
          name='shippingCountry'
          mode='multiple'
          listHeight={256}
          optionFilterProp='label'
          placeholder='Search'
          onChange={submitForm}>
          {countries.map(country => (
            <Option key={country.id} value={country.country_code} label={country.name}>
              {country.name}
            </Option>
          ))}
        </Select>
      </FormItem>

      <FormItem label='Niche'>
        <Select
          getPopupContainer={trigger => trigger.parentNode}
          virtual={false}
          name='niche'
          showArrow
          placeholder='Select'
          mode='multiple'
          loading={!niches.length}
          onChange={submitForm}
          allowClear>
          {niches.map(niche => (
            <Option label={niche.title} key={niche.id} value={niche.id}>
              {niche.title}
            </Option>
          ))}
        </Select>
      </FormItem>

      <FormItem label='Latest Opt-In'>
        <DatePicker
          onChange={submitForm}
          name='latestOptInDate'
          placeholder='Select Date'
          format='MMMM D, Y'
        />
      </FormItem>

      <FormItem label='Latest Activation'>
        <DatePicker
          onChange={submitForm}
          name='latestActivationDate'
          placeholder='Select Date'
          format='MMMM D, Y'
        />
      </FormItem>

      <FormItem label='Latest Completion'>
        <DatePicker
          onChange={submitForm}
          name='latestCompletionDate'
          placeholder='Select Date'
          format='MMMM D, Y'
        />
      </FormItem>

      <div className='checkboxes'>
        <Checkbox name='hasCreatorProfile' onChange={submitForm}>
          Creator Profile Claimed
        </Checkbox>

        <Checkbox name='hasSocialsConnected' onChange={submitForm}>
          Socials Connected
        </Checkbox>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .ant-input,
  .ant-select,
  .ant-picker {
    width: 100%;
  }

  .filter-row {
    display: flex;
    gap: 12px;
  }

  .checkboxes {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .ant-checkbox-wrapper {
      margin: 0;
    }
  }
`

import {
  CaretDownFilled,
  CaretUpFilled,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Alert, Button, Empty, Modal, Spin, Tooltip } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Checkbox, DatePicker, Input, Select } from 'formik-antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'

import { API_URL } from '../../../../constants'
import FormItem from '../../../utility/FormItem'
import { Wrapper } from '../campaign/CampaignsList'

const { Option } = Select

const OptInsList = () => {
  // #region Constants
  const history = useHistory()
  const [optIns, setOptIns] = useState([])
  const [newOptInVisible, setNewOptInVisible] = useState(false)
  const [allCampaigns, setAllCampaigns] = useState([])
  const [fetchingCampaigns, setFetchingCampaigns] = useState(false)
  const [fetchingUsers, setFetchingUsers] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [duplicateOptIn, setDuplicateOptIn] = useState(false)
  const [currentSortItem, setCurrentSortItem] = useState('created-desc')
  const [formData, setFormData] = useState({ sort: currentSortItem })
  const userFetch = useRef(0)
  const submitRef = useRef(0)
  const campaignFetch = useRef(0)
  const scrollRef = useRef()
  // #endregion Constants

  // #region Queries
  const fetchOptIns = async ({ pageParam = 0 }) => {
    const { data } = await axios.get(`${API_URL}/admin-access/opt-ins/${pageParam}`, {
      params: formData,
    })
    return data
  }

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetchingMore, status } =
    useInfiniteQuery(['opt-ins', formData], fetchOptIns, {
      getNextPageParam: lastPage => lastPage.nextCursor,
    })
  // #endregion Queries

  // #region Effects
  useEffect(() => {
    const array = data?.pages.flatMap(page => page.optIns) || []
    setOptIns(array)
  }, [data, status])
  // #endregion Effects

  // #region Functions
  const handleSearchCampaigns = async search => {
    campaignFetch.current++
    const fetchId = campaignFetch.current
    setFetchingCampaigns(true)

    try {
      const res = await axios.get(`${API_URL}/admin/campaigns/1`, {
        params: { search },
      })

      if (campaignFetch.current === fetchId) {
        setAllCampaigns(res.data.campaigns)
      }
    } catch (err) {
      if (campaignFetch.current === fetchId) {
        setAllCampaigns([])
      }
    } finally {
      if (campaignFetch.current === fetchId) {
        setFetchingCampaigns(false)
      }
    }
  }

  const handleSearchUsers = async search => {
    userFetch.current++
    const fetchId = userFetch.current
    setFetchingUsers(true)

    try {
      const res = await axios.get(`${API_URL}/users`, {
        params: { search },
      })

      if (userFetch.current === fetchId) {
        setAllUsers(res.data.users)
      }
    } catch (err) {
      if (userFetch.current === fetchId) {
        setAllUsers([])
      }
    } finally {
      if (userFetch.current === fetchId) {
        setFetchingUsers(false)
      }
    }
  }

  const sortBy = sortItem => {
    const updatedSortItem =
      currentSortItem === `${sortItem}-desc` ? `${sortItem}-asc` : `${sortItem}-desc`
    setCurrentSortItem(updatedSortItem)
    setFormData({ ...formData, sort: updatedSortItem })
  }

  const handleSearch = data => {
    submitRef.current++
    const thisSubmit = submitRef.current
    setTimeout(() => {
      if (thisSubmit === submitRef.current) {
        setFormData(data)
      }
    }, 300)
  }

  const handleScroll = () => {
    const { scrollTop, offsetHeight, scrollHeight } = scrollRef.current
    const actualDistance = scrollHeight - (scrollTop + offsetHeight)
    if (actualDistance < 400 && !isFetchingMore && hasNextPage) {
      fetchNextPage()
    }
  }

  const createOptIn = async (data, { setStatus }) => {
    const { campaignId, userId, allowDuplicate } = data
    const campaign = allCampaigns.find(c => c.id === campaignId)

    try {
      const res = await axios.post(
        `${API_URL}/brand/${campaign.brand.id}/campaign/${campaignId}/opt-ins`,
        {
          userId,
          allowDuplicate,
        }
      )

      const { optInId } = res.data

      history.push(`/opt-in/${optInId}`)
    } catch (err) {
      setStatus(err.response?.data?.err || 'An unknown error occurred')
      if (err.response?.data?.errCode === 'OP_NOT_ALLOWED') {
        setDuplicateOptIn(true)
      }
    }
  }
  // #endregion Functions

  return (
    <Wrapper>
      <Formik onSubmit={handleSearch} initialValues={formData}>
        {({ submitForm }) => {
          return (
            <>
              <div className='header-wrapper'>
                <h1>Opt-Ins</h1>
                {status === 'success' ? (
                  <span className='num-results'>
                    {optIns?.length} / {data?.pages[0]?.totalResults?.toLocaleString() || 0} results
                  </span>
                ) : (
                  <LoadingOutlined spin />
                )}
                <div className='header-buttons'>
                  <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setNewOptInVisible(true)
                    }}>
                    New Opt-In
                  </Button>
                </div>
              </div>

              <Form className='filters'>
                <Input
                  name='search'
                  placeholder='Search opt-ins'
                  prefix={<SearchOutlined />}
                  onChange={submitForm}
                  allowClear
                  style={{ width: 'auto' }}
                />
                <Select
                  onChange={submitForm}
                  name='campaignId'
                  loading={fetchingCampaigns}
                  placeholder='Campaign'
                  showSearch
                  filterOption={false}
                  onSearch={val => handleSearchCampaigns(val)}
                  allowClear>
                  {allCampaigns.map((campaign, i) => (
                    <Select.Option key={i} value={campaign.id}>{`${campaign.title}`}</Select.Option>
                  ))}
                </Select>
                <Select
                  onChange={submitForm}
                  name='userId'
                  loading={fetchingUsers}
                  placeholder='User'
                  showSearch
                  filterOption={false}
                  onSearch={val => handleSearchUsers(val)}
                  allowClear>
                  {allUsers.map((user, i) => (
                    <Select.Option key={i} value={user.id}>
                      {`${user.firstName} ${user.lastName} (${user.email})`}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  name='status'
                  placeholder='Status'
                  showArrow
                  onChange={submitForm}
                  allowClear>
                  <Option value='pending'>Pending</Option>
                  <Option value='activated'>Activated</Option>
                  <Option value='completed'>Completed</Option>
                  <Option value='cancelled'>Cancelled</Option>
                </Select>
                <DatePicker
                  onChange={submitForm}
                  name='createdFromDate'
                  placeholder='Created From Date'
                  format='MMMM D, Y'
                />
                <DatePicker
                  onChange={submitForm}
                  name='createdToDate'
                  placeholder='Created To Date'
                  format='MMMM D, Y'
                />
                <DatePicker
                  onChange={submitForm}
                  name='activatedFromDate'
                  placeholder='Activated From Date'
                  format='MMMM D, Y'
                />
                <DatePicker
                  onChange={submitForm}
                  name='activatedToDate'
                  placeholder='Activated To Date'
                  format='MMMM D, Y'
                />
                <DatePicker
                  onChange={submitForm}
                  name='completedFromDate'
                  placeholder='Completed From Date'
                  format='MMMM D, Y'
                />
                <DatePicker
                  onChange={submitForm}
                  name='completedToDate'
                  placeholder='Completed To Date'
                  format='MMMM D, Y'
                />
                <DatePicker
                  onChange={submitForm}
                  name='cancelledFromDate'
                  placeholder='Cancelled From Date'
                  format='MMMM D, Y'
                />
                <DatePicker
                  onChange={submitForm}
                  name='cancelledToDate'
                  placeholder='Cancelled To Date'
                  format='MMMM D, Y'
                />
                <Checkbox onChange={submitForm} name='manualOptIns'>
                  Manual Opt-Ins
                </Checkbox>
              </Form>
            </>
          )
        }}
      </Formik>

      <Modal
        title='New Opt-In'
        footer={null}
        onCancel={() => setNewOptInVisible(false)}
        open={newOptInVisible}>
        <Formik
          onSubmit={createOptIn}
          initialValues={{ campaignId: '', userId: '', allowDuplicate: false }}>
          {({ status, isSubmitting }) => (
            <Form>
              <FormItem label='Campaign' name='campaignId'>
                <Select
                  style={{ width: '100%' }}
                  name='campaignId'
                  loading={fetchingCampaigns}
                  placeholder='Search'
                  showSearch
                  filterOption={false}
                  onSearch={val => handleSearchCampaigns(val)}>
                  {allCampaigns.map((campaign, i) => (
                    <Select.Option key={i} value={campaign.id}>
                      {`${campaign.title} (${campaign.status})`}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem label='User' name='userId'>
                <Select
                  style={{ width: '100%' }}
                  name='userId'
                  loading={fetchingUsers}
                  placeholder='Search'
                  showSearch
                  filterOption={false}
                  onSearch={val => handleSearchUsers(val)}>
                  {allUsers.map((user, i) => (
                    <Select.Option key={i} value={user.id}>
                      {`${user.firstName} ${user.lastName} (${user.email})`}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
              {status && (
                <Alert message={status} type='error' showIcon style={{ marginBottom: '20px' }} />
              )}
              {duplicateOptIn && (
                <FormItem name='allowDuplicate'>
                  <Checkbox name='allowDuplicate'>Allow Duplicate</Checkbox>
                </FormItem>
              )}
              <Button loading={isSubmitting} htmlType='submit' type='primary'>
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>

      <div className='list-inner' onScroll={handleScroll} ref={scrollRef}>
        <div className='list-header opt-ins'>
          <div className='thumbnail' />
          <div className='campaign'>Campaign</div>
          <div className='brand'>Brand</div>
          <div className='user'>User</div>
          <div className='status'>Status</div>
          <div
            className='date sortable'
            onClick={() => sortBy('created')}
            onKeyDown={() => sortBy('created')}
            role='button'
            tabIndex={0}>
            Opt-In Date
            <div className='sort-arrows'>
              <span className={`sort-arrow ${currentSortItem === 'created-desc' && 'current'}`}>
                <CaretUpFilled />
              </span>
              <span className={`sort-arrow ${currentSortItem === 'created-asc' && 'current'}`}>
                <CaretDownFilled />
              </span>
            </div>
          </div>
        </div>
        {status === 'success' &&
          (optIns?.length ? (
            <>
              {optIns.map(optIn => (
                <Link to={`/opt-in/${optIn.id}`} key={optIn.id} className='list-item opt-ins'>
                  <div className='thumbnail'>
                    <img
                      src={!!optIn.campaign.images[0] && optIn.campaign.images[0].sizes[0].url}
                      alt=''
                    />
                  </div>
                  <div className='campaign'>{optIn.campaign.title}</div>
                  <div className='brand'>{optIn.campaign.brand.name}</div>
                  <div className='user'>
                    <p className='name'>{`${optIn.user.firstName} ${optIn.user.lastName}`}</p>
                    <p className='email'>{optIn.user.email}</p>
                  </div>
                  <div className={`status ${optIn.status}`}>{optIn.status}</div>
                  <div className='date'>
                    {optIn.extraData?.manuallyCreatedByAdmin && (
                      <Tooltip
                        title={`Manually created by admin or agency user (User ID: ${optIn.extraData?.manuallyCreatedByAdmin.adminId})`}>
                        <ExclamationCircleOutlined />
                      </Tooltip>
                    )}
                    {moment(optIn.created).format('ll')}
                  </div>
                </Link>
              ))}
              {isFetchingNextPage && (
                <div className='loading'>
                  <Spin />
                </div>
              )}
            </>
          ) : (
            <div className='no-results'>
              <Empty description='No opt-ins found.' />
            </div>
          ))}

        {status === 'loading' && (
          <div className='loading'>
            <Spin />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default OptInsList

import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
  MessageOutlined,
} from '@ant-design/icons'
import { Button, Col, Empty, message, Row, Spin, Tooltip, Tag, Alert } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { DatePicker, InputNumber, Select } from 'formik-antd'
import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useQuery, useQueryClient } from 'react-query'
import { Link, useParams } from 'react-router-dom'

import OptInSteps from './OptInSteps'
import instagram from '../../../../assets/images/instagram-color-square.svg'
import tiktok from '../../../../assets/images/tiktok-color-square.svg'
import youtube from '../../../../assets/images/youtube-color-square.svg'
import { API_URL } from '../../../../constants'
import { NotFound } from '../../../general/NotFound'
import FormItem from '../../../utility/FormItem'
import { Wrapper } from '../user/UserPage'
const socialIcons = { instagram, tiktok, youtube }

const { Option } = Select

const OptIn = () => {
  const queryClient = new useQueryClient()
  const id = parseInt(useParams().id)

  const [copied, setCopied] = useState(false)
  const [acceptPaymentLoading, setAcceptPaymentLoading] = useState(false)

  const { data: optIn, status } = useQuery(['opt-in', parseInt(id)], async () => {
    try {
      const { data } = await axios.get(`${API_URL}/admin-access/opt-in/${id}`)
      return data
    } catch (err) {
      if (err.response.status === 404) return null
    }
  })

  const paymentStatusColor = status => {
    // Possible types of payments from trolley API
    switch (status) {
      case 'pending':
        return 'warning'
      case 'processing':
        return 'processing'
      case 'processed':
        return 'success'
      case 'failed':
        return 'error'
      case 'returned':
        return 'error'
      default:
        return 'warning'
    }
  }

  const handleAcceptPayment = async () => {
    setAcceptPaymentLoading(true)
    axios
      .post(`${API_URL}/admin-access/opt-in/`, {
        id,
        paymentAmount: optIn.extraData.requestedPaymentAmount,
        extraData: {
          requestedPaymentStatus: 'accepted',
        },
      })
      .then(() => {
        message.success('Payment Accepted')
        queryClient.invalidateQueries(['opt-in', id])
      })
      .catch(() => {
        message.error('Error Accepting Payment')
      })
      .finally(() => setAcceptPaymentLoading(false))
  }

  const updateOptIn = async values => {
    try {
      await axios.put(`${API_URL}/admin/opt-in/${optIn.id}`, { ...values })
      message.success('Opt-In Updated')
      queryClient.invalidateQueries(['opt-in', optIn.id])
    } catch (err) {
      message.error('Error Updating Opt-In')
    }
  }

  return (
    <Wrapper>
      {status === 'success' &&
        (optIn ? (
          <Formik
            initialValues={{
              status: optIn.status,
              paymentAmount: optIn.paymentAmount,
              created: optIn.created,
              channels: optIn.extraData?.channels,
            }}
            onSubmit={updateOptIn}>
            {({ isSubmitting }) => (
              <Form className='page-inner'>
                <div className='header'>
                  <Link to='/opt-ins'>
                    <ArrowLeftOutlined /> All Opt-Ins
                  </Link>

                  <h1>
                    {optIn.user.firstName} {optIn.user.lastName}{' '}
                    <span className='grey'>({optIn.campaign.title})</span>
                  </h1>

                  <div className='header-group'>
                    <a
                      href={`https://brands.creator.co/inbox/${optIn.campaign.id}/chat/${optIn.id}?brand=${optIn.campaign.brandId}`}
                      target='_blank'
                      rel='noopenner noreferrer'>
                      <Button type='secondary' icon={<MessageOutlined />}>
                        Chat
                      </Button>
                    </a>
                    <Button
                      loading={isSubmitting}
                      htmlType='submit'
                      type='primary'
                      icon={<SaveOutlined />}>
                      Save
                    </Button>
                  </div>
                </div>

                {!!optIn.extraData?.manuallyCreatedByAdmin && (
                  <Alert
                    type='info'
                    showIcon
                    description={`This opt-in was manually created by an admin or agency user (User ID: ${optIn.extraData?.manuallyCreatedByAdmin.adminId})`}
                  />
                )}

                <div className='sections'>
                  <section>
                    <div className='section-header'>
                      <h2>IDs</h2>
                    </div>
                    <div className='id-wrapper'>
                      Opt-In
                      <CopyToClipboard
                        text={optIn.id}
                        onCopy={() => {
                          setCopied(optIn.id)
                          setTimeout(() => setCopied(false), 1000)
                        }}>
                        <Tooltip open={copied === optIn.id} title='Copied'>
                          <span className='copy-id'>#{optIn.id}</span>
                        </Tooltip>
                      </CopyToClipboard>
                    </div>

                    <div className='id-wrapper'>
                      User
                      <CopyToClipboard
                        text={optIn.userId}
                        onCopy={() => {
                          setCopied(optIn.userId)
                          setTimeout(() => setCopied(false), 1000)
                        }}>
                        <Tooltip open={copied === optIn.userId} title='Copied'>
                          <span className='copy-id'>#{optIn.userId}</span>
                        </Tooltip>
                      </CopyToClipboard>
                      <Link to={`/user/${optIn.userId}`}>
                        View <ArrowRightOutlined />
                      </Link>
                    </div>

                    <div className='id-wrapper'>
                      Campaign
                      <CopyToClipboard
                        text={optIn.campaignId}
                        onCopy={() => {
                          setCopied(optIn.campaignId)
                          setTimeout(() => setCopied(false), 1000)
                        }}>
                        <Tooltip open={copied === optIn.campaignId} title='Copied'>
                          <span className='copy-id'>#{optIn.campaignId}</span>
                        </Tooltip>
                      </CopyToClipboard>
                      <Link to={`/campaign/${optIn.campaignId}`}>
                        View <ArrowRightOutlined />
                      </Link>
                    </div>
                  </section>

                  <section>
                    <div className='section-header'>
                      <h2>General Settings</h2>
                    </div>
                    <Row gutter={16}>
                      <Col span={12}>
                        <FormItem label='Created' name='created'>
                          <DatePicker name='created' style={{ width: '100%' }} format='MMMM D, Y' />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem label='Opt-In Status' name='status'>
                          <Select name='status' style={{ width: '100%' }}>
                            <Option value='pending'>Pending</Option>
                            <Option value='activated'>Activated</Option>
                            <Option value='completed'>Completed</Option>
                            <Option value='cancelled'>Cancelled</Option>
                          </Select>
                        </FormItem>
                      </Col>
                    </Row>
                    {optIn.campaign.socialChannels?.length > 1 && (
                      <Row gutter={16}>
                        <Col span={12}>
                          <FormItem label='Channel Selection' name='channels'>
                            <Select name='channels' mode='multiple' style={{ width: '100%' }}>
                              {optIn.campaign.socialChannels.map((channel, i) => (
                                <Option key={i} value={channel}>
                                  <img
                                    src={socialIcons[channel]}
                                    alt=''
                                    style={{ height: '20px', marginRight: '8px' }}
                                  />
                                  {channel === 'instagram'
                                    ? 'Instagram'
                                    : channel === 'tiktok'
                                      ? 'TikTok'
                                      : 'YouTube'}
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </Col>
                      </Row>
                    )}
                  </section>

                  {optIn?.campaign?.maxPaidAmount && (
                    <section className='payments'>
                      <div className='section-header'>
                        <h2>Payment</h2>
                        <Tag color={paymentStatusColor(optIn?.trolleyPayments[0]?.status)}>
                          {optIn?.trolleyPayments[0]?.status || 'PENDING'}
                        </Tag>
                      </div>
                      <Row gutter={16}>
                        <Col span={12}>
                          <FormItem
                            name='paymentAmount'
                            label='Amount'
                            sublabel={`(Default: $${optIn.campaign.maxPaidAmount})`}>
                            <InputNumber
                              name='paymentAmount'
                              style={{ minWidth: '100%' }}
                              formatter={value =>
                                value && `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                          </FormItem>
                        </Col>
                      </Row>
                      {optIn.extraData.batchId && (
                        <p>
                          Trolley Batch ID: <b>{optIn.extraData.batchId}</b>
                        </p>
                      )}
                      {optIn.extraData.requestedPaymentStatus && (
                        <>
                          <p>
                            Offer Status:{' '}
                            <b className='capitalize'>{optIn.extraData.requestedPaymentStatus}</b>
                            {optIn.extraData.requestedPaymentStatus === 'pending' && (
                              <Button
                                type='link'
                                loading={acceptPaymentLoading}
                                onClick={handleAcceptPayment}>
                                Accept
                              </Button>
                            )}
                          </p>
                          <p>
                            Amount Offered: <b>${optIn.extraData?.requestedPaymentAmount || 0}</b>
                          </p>
                        </>
                      )}
                    </section>
                  )}

                  <section>
                    <div className='section-header'>
                      <h2>Campaign Steps</h2>
                    </div>
                    {optIn.campaignSteps?.length ? (
                      <OptInSteps optIn={optIn} />
                    ) : (
                      <Empty description='No steps.' />
                    )}
                  </section>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <NotFound message='Opt-in not found.' buttonText='Go Back' to='/opt-ins' />
        ))}

      {status === 'loading' && (
        <div className='loading'>
          <Spin /> Loading...
        </div>
      )}
    </Wrapper>
  )
}

export default OptIn

import React from 'react'

import { CampaignChatTemplate } from './CampaignChatTemplate'

export const CampaignChatTemplates = () => {
  const occurrences = [
    { label: 'Opt-In', id: 'optInMessageTemplate' },
    { label: 'Activation', id: 'activationMessageTemplate' },
    { label: 'Cancellation', id: 'cancellationMessageTemplate' },
    { label: 'Completion', id: 'completionMessageTemplate' },
  ]

  return (
    <div className='section-wrapper'>
      <h3>Automatic Messages</h3>

      <div className='section-body'>
        <div className='chat-templates'>
          {occurrences.map((occurrence, i) => (
            <CampaignChatTemplate key={i} occurrence={occurrence} />
          ))}
        </div>
      </div>
    </div>
  )
}

import { DeleteOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Input as AntInput, Button, Collapse, message, Modal, Radio } from 'antd'
import axios from 'axios'
import { FieldArray } from 'formik'
import { Checkbox, Input, InputNumber, Select } from 'formik-antd'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'

import ShopifyProducts from './ShopifyProducts'
import { API_URL } from '../../../../constants'
import FormItem from '../../../utility/FormItem'

export const CampaignCompensation = ({ values, setFieldValue, campaign }) => {
  const queryCache = useQueryClient()
  const brandId = campaign.brandId
  const [numOptInVariables, setNumOptInVariables] = useState(undefined)
  const [variationToDelete, setVariationToDelete] = useState(null)
  const [optionToDelete, setOptionToDelete] = useState(null)
  const [deletingVariation, setDeletingVariation] = useState(null)
  const [deletingOption, setDeletingOption] = useState(null)
  const [productValueType, setProductValueType] = useState(values?.minValue ? 'ranged' : 'fixed')

  const { data: productLists, status: productListsStatus } = useQuery(
    ['product-lists', brandId],
    () => axios.get(`${API_URL}/brand/${brandId}/product-list`).then(res => res.data.lists)
  )

  const handleDeleteVariation = async variableIndex => {
    const variable = values.variables[variableIndex]
    setDeletingVariation(variable.id)

    try {
      await axios.delete(`${API_URL}/brand/${brandId}/campaign/variable/${variable.id}`)
      await queryCache.invalidateQueries(['campaign', campaign.id])
      message.success('Deleted Successfully')

      const updatedVariables = values.variables.filter((v, index) => index !== variableIndex)
      setFieldValue('variables', updatedVariables)
    } catch (err) {
      message.error('Error deleting variation')
    } finally {
      setDeletingVariation(null)
    }
  }

  const handleDeleteOption = async (variableIndex, optionIndex) => {
    const option = values.variables[variableIndex].options[optionIndex]
    setDeletingOption(option.id)

    try {
      await axios.delete(`${API_URL}/brand/${brandId}/campaign/variable/option/${option.id}`)
      await queryCache.invalidateQueries(['campaign', campaign.id])
      message.success('Deleted Successfully')

      const updatedVariables = values.variables.map((v, index) =>
        index === variableIndex
          ? { ...v, options: v.options.filter((o, oIndex) => oIndex !== optionIndex) }
          : v
      )
      setFieldValue('variables', updatedVariables)
    } catch (err) {
      message.error('Error deleting option')
    } finally {
      setDeletingOption(null)
    }
  }

  return (
    <Wrapper>
      <FormItem>
        <Checkbox name='paidCampaign'>Paid Campaign</Checkbox>
      </FormItem>

      {values.paidCampaign && (
        <div className='section-wrapper'>
          <FormItem label='Payment'>
            <Radio.Group
              name='paymentType'
              value={values.paymentType}
              onChange={e => {
                if (e.target.value === 'fixed') {
                  // minPaidAmount should only be set for ranged payments
                  setFieldValue('minPaidAmount', null)
                }
                if (e.target.value === 'ranged') {
                  // enableNegotiation setting only meant for fixed payments (ranged payments are negotiated by default)
                  setFieldValue('enableNegotiation', false)
                }
                setFieldValue('paymentType', e.target.value)
              }}>
              <Radio value='fixed'>Fixed</Radio>
              <Radio value='ranged'>Range</Radio>
            </Radio.Group>

            {values.paymentType && (
              <div className='form-row' style={{ marginTop: '10px' }}>
                {values.paymentType === 'ranged' && (
                  <>
                    <InputNumber
                      // if this has a value, the payment type is ranged and maxPaidAmount should be required
                      name='minPaidAmount'
                      disabled={values.paymentType === 'fixed'}
                      formatter={value =>
                        value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      } // format to currency
                      parser={value => value.replace(/\$\s?|(,*)/g, '')} // remove $ and commas for backend
                      placeholder='Min Amount'
                      onChange={() => {
                        setFieldValue('enableNegotiation', false)
                      }}
                    />
                    -
                  </>
                )}
                <InputNumber
                  name='maxPaidAmount'
                  formatter={value => value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  placeholder='Max Amount'
                />
              </div>
            )}
          </FormItem>

          {values.paymentType !== 'ranged' && (
            <FormItem>
              <Checkbox
                name='enableNegotiation'
                disabled={values.minPaidAmount}
                checked={values.minPaidAmount ? false : values.enableNegotiation}>
                Enable payment negotiation
              </Checkbox>
            </FormItem>
          )}

          <FormItem>
            <Checkbox name='disablePayments'>Disable automatic payments</Checkbox>
          </FormItem>
        </div>
      )}

      <div className='section-wrapper'>
        <FormItem label='Product / Experience Value'>
          <Radio.Group
            name='productValueType'
            defaultValue={values?.minValue ? 'ranged' : 'fixed'}
            onChange={e => {
              setProductValueType(e.target.value)
              setFieldValue('minValue', null)
            }}>
            <Radio value='fixed'>Fixed</Radio>
            <Radio value='ranged'>Range</Radio>
          </Radio.Group>

          <div className='form-row' style={{ marginTop: '10px' }}>
            {values?.minValue || productValueType === 'ranged' ? (
              <>
                <InputNumber
                  name='minValue'
                  placeholder='Min'
                  controls={false}
                  formatter={value => value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  min={0}
                  max={values?.value - 1}
                  onChange={e => setFieldValue('minValue', e)}
                />
                -
                <InputNumber
                  name='value'
                  placeholder='Max'
                  controls={false}
                  min={values?.minValue + 1 || 0}
                  max={999999}
                  formatter={value => value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </>
            ) : (
              <InputNumber
                name='value'
                formatter={value => value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            )}
          </div>
        </FormItem>

        <FormItem label='Credit Card Hold'>
          <InputNumber
            name='holdCost'
            formatter={value => value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </FormItem>
      </div>

      <div className='section-wrapper'>
        <ShopifyProducts brandId={brandId} campaignId={values.id} />
        <FormItem label='Max Products'>
          <InputNumber name='maxShopifyProducts' />
        </FormItem>
      </div>

      <div className='section-wrapper'>
        <FormItem label='Product Description'>
          <Input.TextArea name='productDescription' autoSize={{ minRows: 4, maxRows: 10 }} />
        </FormItem>

        <FormItem label='Fulfillment Type'>
          <Input disabled name='fulfillmentType' />
        </FormItem>

        {values.fulfillmentTYpe === 'other' && (
          <FormItem label='Fulfillment Type Other'>
            <Input disabled name='fulfillmentTypeOther' />
          </FormItem>
        )}

        <FormItem label='Product List'>
          <Select
            name='productListId'
            placeholder='Select'
            style={{ width: '100%', marginBottom: '10px' }}
            allowClear
            loading={productListsStatus === 'loading'}>
            {productLists?.map(list => (
              <Select.Option key={list.id} value={list.id}>
                {list.title}
                {list.deactivated && ' (Deactivated)'}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <h3>Variations</h3>

        <FieldArray
          name='variables'
          render={arrayHelpers => (
            <>
              <Collapse className='variation-collapse'>
                {values.variables.map((variable, variableIndex) => (
                  <Collapse.Panel
                    className='variation'
                    key={`variable-${variable.id || variableIndex}`}
                    accordion
                    header={variable.title || 'Untitled'}>
                    <FormItem label='Title'>
                      <Button
                        type='link'
                        danger
                        className='variations-delete-btn-wrapper'
                        icon={<DeleteOutlined />}
                        loading={deletingVariation === variable.id}
                        onClick={() => {
                          if (typeof variable.id === 'string' && variable.id.includes('temp')) {
                            // hasn't been saved to db, just remove from array
                            arrayHelpers.remove(variableIndex)
                          } else if (variable.optInVariables.length > 0) {
                            // variable has opt-ins associated with it, ask for confirmation
                            setNumOptInVariables(variable.optInVariables.length)
                            setVariationToDelete(variable)
                          } else handleDeleteVariation(variableIndex)
                        }}
                      />
                      <AntInput
                        value={variable.title}
                        onChange={e =>
                          setFieldValue(`variables[${variableIndex}].title`, e.target.value)
                        }
                      />
                    </FormItem>
                    <FormItem label='Description'>
                      <AntInput.TextArea
                        value={variable.description}
                        onChange={e =>
                          setFieldValue(`variables[${variableIndex}].description`, e.target.value)
                        }
                      />
                    </FormItem>

                    <FieldArray
                      name={`variables[${variableIndex}].options`}
                      render={optionHelpers => (
                        <>
                          <h4>Options</h4>
                          {variable.options.map((option, optionIndex) => (
                            <div
                              className='options-wrapper'
                              key={`option-${option.id || optionIndex}`}>
                              <FormItem label='Label'>
                                <Button
                                  type='link'
                                  danger
                                  className='options-delete-btn'
                                  icon={<MinusCircleOutlined />}
                                  loading={deletingOption === option.id}
                                  onClick={() => {
                                    if (
                                      typeof option.id === 'string' &&
                                      option.id.includes('temp')
                                    ) {
                                      // hasn't been saved to db, just remove from array
                                      optionHelpers.remove(optionIndex)
                                    } else if (variable.optInVariables.length > 0) {
                                      // variable has opt-ins associated with it, ask for confirmation
                                      setNumOptInVariables(variable.optInVariables.length)
                                      setOptionToDelete(option)
                                    } else handleDeleteOption(variableIndex, optionIndex)
                                  }}
                                />
                                <AntInput
                                  value={option.title}
                                  onChange={e =>
                                    setFieldValue(
                                      `variables[${variableIndex}].options[${optionIndex}].title`,
                                      e.target.value
                                    )
                                  }
                                />
                              </FormItem>
                              <FormItem label='Description'>
                                <AntInput.TextArea
                                  value={option.description}
                                  onChange={e =>
                                    setFieldValue(
                                      `variables[${variableIndex}].options[${optionIndex}].description`,
                                      e.target.value
                                    )
                                  }
                                />
                              </FormItem>
                            </div>
                          ))}
                          <Button
                            type='secondary'
                            onClick={() =>
                              optionHelpers.push({
                                id: `temp-${variable.options.length}`,
                                title: '',
                                description: '',
                              })
                            }>
                            <PlusOutlined /> Add Option
                          </Button>
                        </>
                      )}
                    />
                  </Collapse.Panel>
                ))}
              </Collapse>

              <Button
                type='primary'
                onClick={() =>
                  arrayHelpers.push({
                    id: `temp-${values.variables.length}`,
                    title: '',
                    options: [],
                  })
                }>
                <PlusOutlined /> Add Variation
              </Button>
            </>
          )}
        />

        <Modal
          title={`Delete ${optionToDelete ? 'Option' : 'Variation'}`}
          open={variationToDelete || optionToDelete}
          okButtonProps={{ danger: true }}
          okText='Delete'
          onOk={() => {
            setNumOptInVariables(undefined)
            if (optionToDelete) {
              handleDeleteOption(optionToDelete)
              setOptionToDelete(null)
            } else {
              handleDeleteVariation(variationToDelete)
              setVariationToDelete(null)
            }
          }}
          onCancel={() => {
            setNumOptInVariables(undefined)
            setOptionToDelete(null)
            setVariationToDelete(null)
          }}>
          <p>
            This {optionToDelete ? 'option' : 'variation'} has <b>{numOptInVariables}</b> opt-ins
            associated with it. Are you sure you want to delete it?
          </p>
        </Modal>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .variation-collapse {
    margin-bottom: 20px;
    .variation {
      position: relative;

      .variations-delete-btn-wrapper {
        position: absolute;
        right: 6px;
        top: 6px;
      }
    }

    .options-wrapper {
      position: relative;
      margin-top: 10px;

      .options-delete-btn {
        position: absolute;
        top: -7px;
        right: 0;
        height: 30px;
      }
    }
  }
`

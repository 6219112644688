import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Empty, Modal, Tag, Tooltip, message } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Checkbox, Input, Mentions } from 'formik-antd'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'

import { API_URL } from '../../../../../constants'
import FormItem from '../../../../utility/FormItem'

const { Option } = Mentions

export const CampaignChatTemplate = ({ occurrence }) => {
  const { id: campaignId } = useParams()
  const { data: campaign } = useQuery(['campaign', parseInt(campaignId)], async () => {
    const { data } = await axios.get(`${API_URL}/campaign/${campaignId}`)
    return data
  })

  const [selectTemplateOpen, setSelectTemplateOpen] = useState(false)
  const [deleteOccurrenceOpen, setDeleteOccurrenceOpen] = useState(null)
  const chatTemplate = campaign?.chatTemplates?.find(
    template => template.occurrence === occurrence.id
  )?.template

  return (
    <Wrapper key={occurrence.id}>
      <div className='occurrence-body'>
        {chatTemplate ? (
          <>
            <div className='chat-template-headers'>
              <div className='template-label'>{chatTemplate.label}</div>
              <div className='template-name'>{chatTemplate.template}</div>
            </div>
          </>
        ) : (
          <div className='no-template'>
            <div>No template set</div>
          </div>
        )}
      </div>
      <div className='occurrence-footer'>
        <div>
          <span className='label'>{occurrence.label}</span>
        </div>
        <div className='actions'>
          {chatTemplate ? (
            <>
              <Button type='text' onClick={() => setSelectTemplateOpen(chatTemplate.id)}>
                <EditOutlined />
              </Button>
              <Tooltip title='Remove Automation'>
                <Button type='text' danger onClick={() => setDeleteOccurrenceOpen(chatTemplate.id)}>
                  <DeleteOutlined />
                </Button>
              </Tooltip>

              <DeleteTemplate
                isOpen={deleteOccurrenceOpen}
                close={() => setDeleteOccurrenceOpen(false)}
                templateId={chatTemplate.id}
                campaignId={campaign.id}
                occurrence={occurrence.id}
              />
            </>
          ) : (
            <>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => setSelectTemplateOpen(true)}>
                Add
              </Button>
            </>
          )}
        </div>
      </div>

      <SelectTemplate
        occurrence={occurrence}
        isOpen={selectTemplateOpen}
        close={() => setSelectTemplateOpen(false)}
        campaign={campaign}
        chatTemplate={chatTemplate}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: space-between;

  .chat-template-headers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    font-family: 'Campton-Medium', Verdana, sans-serif;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;

    .template-label {
      font-size: 16px;
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .template-name {
      font-size: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .occurrence-body {
    margin-bottom: 12px;
  }
  .occurrence-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .label {
      font-size: 14px;
      background-color: #eee;
      padding: 3px 10px;
      border-radius: 10px;
      width: fit-content;
    }
  }
`

export const SelectTemplate = ({ isOpen, close, campaign, occurrence, chatTemplate }) => {
  const queryCache = useQueryClient()

  const [CreateNewTemplateOpen, setCreateNewTemplateOpen] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(chatTemplate?.id)

  const handleTemplateSelect = () => {
    axios
      .put(`${API_URL}/admin-access/admin-template?type=setTemplate`, {
        campaignId: campaign?.id,
        templateId: selectedTemplate,
        occurrence: occurrence.id,
      })
      .then(res => {
        if (res.data.success) {
          message.success('Automated template set')
          queryCache.invalidateQueries(['campaign', campaign.id])
          close()
        }
      })
      .catch(err => {
        message.error(
          err?.response?.data?.message ||
            'Automated template could not be set. Please try again later.'
        )
      })
  }

  return (
    <Drawer
      className='select-template-modal'
      title={`Select Template Sent On ${occurrence.label}`}
      open={isOpen}
      destroyOnClose
      onClose={close}
      width={600}
      footer={null}>
      <TemplatesWrapper>
        <div className='templates'>
          {campaign?.templates && (
            <>
              <h2 className='templates-header'>Brand templates</h2>
              <div className='templates-wrapper'>
                {campaign?.templates?.brandTemplates?.length ? (
                  campaign.templates.brandTemplates.map(template => (
                    <div
                      className='template'
                      key={template.id}
                      value={template.id}
                      onClick={() => setSelectedTemplate(template.id)}
                      onKeyDown={() => setSelectedTemplate(template.id)}
                      role='button'
                      tabIndex={0}
                      style={{
                        backgroundColor:
                          selectedTemplate === template.id ? '#ecf6ff' : 'transparent',
                      }}>
                      <div className='template-div template-label'>{template.label}</div>
                      <div className='template-div template-template'>{template.template}</div>
                    </div>
                  ))
                ) : (
                  <div className='no-results'>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No templates yet' />
                  </div>
                )}
              </div>

              <h2 className='templates-header'>Admin templates</h2>
              <div className='templates-wrapper'>
                {campaign?.templates?.adminTemplates?.length ? (
                  campaign.templates.adminTemplates.map(template => (
                    <div
                      className='template'
                      key={template.id}
                      value={template.id}
                      onClick={() => setSelectedTemplate(template.id)}
                      onKeyDown={() => setSelectedTemplate(template.id)}
                      role='button'
                      tabIndex={0}
                      style={{
                        backgroundColor:
                          selectedTemplate === template.id ? '#ecf6ff' : 'transparent',
                      }}>
                      <div className='template-div template-label'>{template.label}</div>
                      <div className='template-div template-template'>{template.template}</div>
                    </div>
                  ))
                ) : (
                  <div className='no-results'>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No templates yet' />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className='footer-section'>
          <div className='buttons'>
            <Button type='primary' disabled={!selectedTemplate} onClick={handleTemplateSelect}>
              Set {occurrence.label} Message
            </Button>
            <Button
              type='link'
              onClick={() => {
                setCreateNewTemplateOpen(true)
              }}
              icon={<PlusOutlined />}>
              New
            </Button>
          </div>
        </div>
        <CreateTemplate
          isOpen={CreateNewTemplateOpen}
          close={() => setCreateNewTemplateOpen(false)}
          campaign={campaign}
        />
      </TemplatesWrapper>
    </Drawer>
  )
}

export const DeleteTemplate = ({ isOpen, close, campaignId, templateId, occurrence }) => {
  const queryCache = useQueryClient()

  const handleDelete = () => {
    axios
      .delete(`${API_URL}/admin-access/admin-template/`, {
        data: {
          templateId,
          campaignId,
          occurrence,
        },
      })
      .then(res => {
        if (res.data.success) {
          message.success('Automation removed')
          queryCache.invalidateQueries(['campaign', campaignId])
          close()
        }
      })
      .catch(err => message.error(err.response.data))
  }

  return (
    <Modal
      className='delete-template-modal'
      title='Remove Automation'
      open={isOpen}
      onCancel={() => close()}
      footer={[
        <Button key='back' onClick={() => close()}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' danger onClick={() => handleDelete()}>
          Remove
        </Button>,
      ]}>
      <span>
        Are you sure you want to remove this automation from the campaign? The template won&apos;t
        be deleted.
      </span>
    </Modal>
  )
}

export const EditTemplate = ({ editModalOpen, setEditModalOpen, campaign = '', template }) => {
  const options = (
    <>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='firstName}}'>
        FirstName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='lastName}}'>
        LastName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='email}}'>
        Email
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='phone}}'>
        Phone
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='brandName}}'>
        BrandName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='campaignName}}'>
        CampaignName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='campaignSocialChannel}}'>
        CampaignSocialChannel
      </Option>
    </>
  )
  const templateType = campaign ? 'campaign' : 'custom'
  const queryCache = useQueryClient()

  return (
    <Drawer
      className='edit-template-modal'
      title='Edit Template'
      open={editModalOpen}
      closable={false}
      onClose={() => setEditModalOpen(null)}
      footer={null}>
      <Formik
        initialValues={{
          template: template?.template?.template,
          label: template?.template?.label,
        }}
        validationSchema={yup.object().shape({
          template: yup
            .string()
            .required('Please enter a template')
            .min(10, 'Template is too short'),
        })}
        onSubmit={(data, { setSubmitting }) => {
          axios
            .put(`${API_URL}/admin-access/admin-template?type=adminTemplate`, {
              templateId: template.template.id,
              campaignId: campaign.id,
              templateType: templateType,
              label: data.label,
              template: data.template,
            })
            .then(res => {
              setSubmitting(false)
              res.data.success && setEditModalOpen(false)
              message.success('Chat template updated')
              queryCache.invalidateQueries(['campaign', campaign.id])
            })
            .catch(err => {
              setSubmitting(false)
              message.error(
                err?.response?.data?.message ||
                  'Chat template could not be updated. Please try again later.'
              )
            })
        }}>
        {({ isSubmitting, dirty, resetForm }) => (
          <Form>
            <FormItem name='label'>
              <Input name='label' placeholder='e.g. Welcome Message' style={{ width: '100%' }} />
            </FormItem>
            <FormItem name='chat-template'>
              <Mentions
                name='template'
                rows={6}
                autoSize={{ minRows: 6, maxRows: 20 }}
                placeholder='Example: Hello &#x7B;&#x7B;firstName&#x7D;&#x7D; becomes "Hello Jane"'
                prefix={['{{']}>
                {options}
              </Mentions>
            </FormItem>

            <div
              className='nav-button'
              style={{
                display: 'flex',
                justifyContent: 'end',
                gap: '10px',
              }}>
              <Button type='secondary' disabled={!dirty || isSubmitting} onClick={resetForm}>
                Reset
              </Button>
              <Button type='primary' htmlType='submit' loading={isSubmitting}>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  )
}

export const CreateTemplate = ({ isOpen, close, campaign }) => {
  const queryCache = useQueryClient()

  const options = (
    <>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='firstName}}'>
        FirstName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='lastName}}'>
        LastName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='email}}'>
        Email
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='phone}}'>
        Phone
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='brandName}}'>
        BrandName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='campaignName}}'>
        CampaignName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='campaignSocialChannel}}'>
        CampaignSocialChannel
      </Option>
    </>
  )

  return (
    <Drawer
      className='create-template-modal'
      title='New Template'
      open={isOpen}
      destroyOnClose
      width={500}
      closable
      onClose={() => close()}
      footer={null}>
      <p>
        Chat templates are designed to streamline your collaboration efforts, providing an efficient
        way to craft personalized messages to your creators.
      </p>
      <p>
        To incorporate predefined variables in your message, simply type <Tag>&#x7B;&#x7B;</Tag> and
        select the variable you wish to use.
      </p>
      <Formik
        initialValues={{ template: '', occurrence: '', label: '', admin: '' }}
        validationSchema={yup.object().shape({
          template: yup
            .string()
            .required('Please enter a template')
            .min(10, 'Template is too short'),
          admin: yup.boolean().notRequired(),
        })}
        onSubmit={(data, { setSubmitting, setStatus }) => {
          axios
            .post(`${API_URL}/admin-access/admin-template`, {
              campaignId: campaign.id,
              brandId: campaign.brandId,
              ...data,
            })
            .then(res => {
              res.data.success && message.success('Chat template created successfully')
              setSubmitting(false)
              close()
              setStatus({ success: true })
              queryCache.invalidateQueries(['campaign', campaign.id])
            })
            .catch(err => {
              setSubmitting(false)
              message.error(err?.response?.data?.message || 'Something went wrong')
              setStatus({ success: false })
            })
        }}>
        {({ isSubmitting, dirty, resetForm }) => (
          <Form>
            <FormItem name='label' label='Label'>
              <Input name='label' placeholder='e.g. Welcome Message' />
            </FormItem>
            <FormItem name='template' label='Message'>
              <Mentions
                name='template'
                rows={6}
                autoSize={{ minRows: 6 }}
                placeholder='Example: Hello &#x7B;&#x7B;firstName&#x7D;&#x7D; becomes "Hello Jane"'
                prefix={['{{']}>
                {options}
              </Mentions>
            </FormItem>
            <FormItem name='admin'>
              <Checkbox name='admin'>Admin Template</Checkbox>
            </FormItem>
            <div
              className='nav-button'
              style={{
                display: 'flex',
                justifyContent: 'end',
                gap: '10px',
              }}>
              <Button type='secondary' disabled={!dirty || isSubmitting} onClick={resetForm}>
                Reset
              </Button>
              <Button
                type='primary'
                htmlType='submit'
                disabled={!dirty || isSubmitting}
                loading={isSubmitting}>
                Create
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  )
}

const TemplatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  .templates-header {
    margin: 10px 0;
  }
  .templates {
    flex: 1;
    max-height: max-content;
    overflow-x: hidden;
    ${props => props.theme.scrollbar}
    .template {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      padding: 10px;
      background-color: #f2f2f2;
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid lightgrey;
      padding: 10px;
      margin: 10px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #e0e0e0;
      }

      .template-div {
        overflow-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .template-label {
        font-size: 16px;
        font-weight: bold;
      }
      .template-template {
        font-size: 14px;
      }
    }
  }

  .templates-wrapper {
    margin-bottom: 30px;
  }

  .no-results {
    background: #f6f6f6;
    padding: 20px;
    border-radius: 5px;
  }

  .footer-section {
    margin-top: 20px;
    .buttons {
      display: flex;
      justify-content: space-between;
      gap: 12px;
    }
  }
`

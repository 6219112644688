import { DeleteOutlined, EyeOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, message, Upload } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import axios from 'axios'
import React, { useState } from 'react'
import Resizer from 'react-image-file-resizer'
import { useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'

import { API_URL } from '../../../../constants'
import { capitalizeFirstLetter, isVideo } from '../../../../utils'
import VideoItem from '../../../general/VideoItem'

const { Dragger } = Upload

function resolveImageUrl(image) {
  if (!image?.sizes?.[0]?.url) return

  if (isVideo(image.sizes[0].url)) image.sizes[0].url

  return image.sizes[0].url.includes('creatorco.nyc3.digitaloceanspaces.com') && image.sizes[1]?.url
    ? image.sizes[1].url
    : image.sizes[0].url
}

const CampaignImages = ({ campaignId, brandId }) => {
  const queryClient = new useQueryClient()

  const { data: campaignData } = useQuery(['campaign', campaignId], () =>
    axios.get(`${API_URL}/brand/${brandId}/campaign/${campaignId}`).then(res => res.data)
  )
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [type, setType] = useState('')
  const [deleteImageUrlOrId, setDeleteImageUrlOrId] = useState(null)
  const [viewImageURL, setViewImageURL] = useState(null)
  const [deleteType, setDeleteType] = useState('')
  const [deleting, setDeleting] = useState(false)

  const resizeFile = file => {
    const gallery_thumbnail = new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        150,
        150,
        'JPEG',
        80,
        0,
        uri => {
          resolve(uri)
        },
        'file'
      )
    })

    const single = new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        510,
        510,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri)
        },
        'file'
      )
    })
    const thumbnail = new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        'JPEG',
        80,
        0,
        uri => {
          resolve(uri)
        },
        'file'
      )
    })
    if (type === 'inspiration') return Promise.all([single])
    if (type === 'primary') return Promise.all([gallery_thumbnail, single, thumbnail])
    return Promise.all([gallery_thumbnail, single])
  }

  const handleUpload = async fileData => {
    const formdata = new FormData()
    let isVideo = false
    // image
    if (['image/jpeg', 'image/jpg', 'image/png'].includes(fileData.file.type)) {
      const image = await resizeFile(fileData.file)
      formdata.append('gallery_thumbnail', image[0])
      formdata.append('single', image[1])
      if (type === 'primary') formdata.append('thumbnail', image[2])
    } else {
      /* video handling */
      isVideo = true
      formdata.append('video', fileData.file)
    }
    // upload request
    const result = await axios.post(
      `${API_URL}/campaign-images-upload/${campaignId}?type=${type}`,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    // response handling
    if (result.data.success) {
      await queryClient.invalidateQueries(['campaign', campaignId])
      message.success(`${isVideo ? 'Video' : 'Images'} uploaded successfully`)
      setIsUploadModalOpen(false)
    }
  }

  const deleteImage = async () => {
    setDeleting(true)
    try {
      await axios.delete(`${API_URL}/brand/${brandId}/campaign/${campaignId}/image`, {
        data: {
          imageType: deleteType,
          ...(deleteType === 'inspiration'
            ? { url: deleteImageUrlOrId }
            : { campaignImageId: deleteImageUrlOrId }),
        },
      })
      await queryClient.invalidateQueries(['campaign', campaignId])
      message.success('File deleted successfully')
      setDeleteImageUrlOrId(null)
    } catch (err) {
      message.error(err.response.data?.err || 'Failed to delete file')
    }
    setDeleting(false)
  }

  const props = {
    name: 'file',
    multiple: true,
    accept: 'image/*, video/mp4',
    action: `${API_URL}/campaign-images-upload/${campaignId}?type=${type}`,
    data: { type },
    maxCount: type === 'primary' ? 1 : 10,

    //* can also limit file type in the accept prop
    beforeUpload(file) {
      // only allow 10 images to be uploaded

      const isLt2M = file.size / 1024 / 1024 < 200
      if (!isLt2M) {
        message.error('File must smaller than 200MB!')
      }
      return isLt2M
    },
  }

  const primaryImage = campaignData?.primaryImage?.sizes?.find(s => s.size === 'single')

  return (
    <Wrapper>
      <div className='section-wrapper images-section'>
        <h2>Hero Image</h2>
        <div className='images'>
          {primaryImage && (
            <div className='image-wrapper'>
              <img src={primaryImage.url} alt='' />
              <div className='overlay'>
                <Button
                  className='view-btn'
                  onClick={() => {
                    setViewImageURL(primaryImage.url)
                  }}>
                  <EyeOutlined />
                </Button>
              </div>
            </div>
          )}
        </div>
        <Button
          type='primary'
          icon={<UploadOutlined />}
          onClick={() => {
            setType('primary')
            setIsUploadModalOpen(true)
          }}>
          Upload
        </Button>
      </div>

      <div className='section-wrapper images-section'>
        <h2>Gallery</h2>
        <div className='images'>
          {campaignData?.images
            ?.filter(image => image.type === 'gallery')
            .map((image, key) => (
              <div className='image-wrapper' key={key}>
                {isVideo(image.sizes[0]?.url) ? (
                  <VideoItem url={image.sizes[0]?.url} />
                ) : (
                  <img src={image.sizes[0]?.url} alt='' />
                )}
                <div className='overlay'>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '5px',
                    }}>
                    <Button
                      className='view-btn'
                      onClick={() => {
                        setViewImageURL(resolveImageUrl(image))
                      }}>
                      <EyeOutlined />
                    </Button>
                    <Button
                      className='delete-btn'
                      onClick={() => {
                        setDeleteType('gallery')
                        setDeleteImageUrlOrId(image.id)
                      }}>
                      <DeleteOutlined />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <Button
          type='primary'
          icon={<UploadOutlined />}
          style={{ width: 'fit-content', position: 'initial' }}
          onClick={() => {
            setType('gallery')
            setIsUploadModalOpen(true)
          }}>
          Upload
        </Button>
      </div>

      <div className='section-wrapper images-section'>
        <h2>Inspiration</h2>
        <div className='images'>
          {campaignData?.metaData?.inspirationImages?.map((image, key) => (
            <div className='image-wrapper' key={key}>
              {isVideo(image.url) ? <VideoItem url={image.url} /> : <img src={image.url} alt='' />}
              <div className='overlay'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '5px',
                  }}>
                  <Button
                    className='view-btn'
                    onClick={() => {
                      setViewImageURL(image.url)
                    }}>
                    <EyeOutlined />
                  </Button>
                  <Button
                    className='delete-btn'
                    onClick={() => {
                      setDeleteType('inspiration')
                      setDeleteImageUrlOrId(image.url?.split('?')[0])
                    }}>
                    <DeleteOutlined />
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Button
          type='primary'
          icon={<UploadOutlined />}
          onClick={() => {
            setType('inspiration')
            setIsUploadModalOpen(true)
          }}>
          Upload
        </Button>
      </div>

      <Modal
        title='Delete File'
        open={!!deleteImageUrlOrId}
        onCancel={() => setDeleteImageUrlOrId(null)}
        footer={null}
        destroyOnClose>
        <p>Are you sure you want to delete this file?</p>
        <div style={{ display: 'flex', gap: '12px' }}>
          <Button
            type='primary'
            danger
            loading={deleting}
            onClick={() => {
              deleteImage()
            }}>
            Delete
          </Button>
          <Button type='secondary' onClick={() => setDeleteImageUrlOrId(null)}>
            Cancel
          </Button>
        </div>
      </Modal>

      <Modal
        title={isVideo(viewImageURL) ? 'Viewing Full Video' : 'Viewing Full Image'}
        open={viewImageURL}
        onCancel={() => {
          setViewImageURL(null)
        }}
        footer={null}
        destroyOnClose>
        <FileWrapper>
          {isVideo(viewImageURL) ? (
            <Video preload='auto' controls autoPlay loop>
              <source src={viewImageURL} type='video/mp4' />
            </Video>
          ) : (
            <img src={viewImageURL} alt='' />
          )}
        </FileWrapper>
      </Modal>

      <Modal
        title={
          type === 'primary' ? 'Replace Hero Image' : `Upload ${capitalizeFirstLetter(type)} Images`
        }
        open={isUploadModalOpen}
        onCancel={() => setIsUploadModalOpen(false)}
        footer={null}
        destroyOnClose
        afterClose={() => {
          setType('')
        }}>
        <Dragger
          customRequest={handleUpload}
          {...props}
          style={{
            marginBottom: '10px',
          }}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            {type === 'primary'
              ? 'Click or drag file to this area to upload.'
              : 'Click or drag files to this area to upload.'}
          </p>
          <p className='ant-upload-hint'>
            {type === 'primary'
              ? 'Only 1 hero image can be uploaded.'
              : 'Support for a single or bulk upload (up to 10).'}
          </p>
        </Dragger>
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .images {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 20px;
  }
  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 200px;
    width: 200px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .image-wrapper:hover .overlay {
    visibility: visible;
    opacity: 1;
  }
  .delete-btn:hover {
    background: ${props => props.theme.crcoCoral};
    border-color: ${props => props.theme.crcoCoral};
    color: white;
  }
  .view-btn:hover {
    background: ${props => props.theme.crcoTechBlue};
    border-color: ${props => props.theme.crcoTechBlue};
    color: white;
  }
  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: grid;
    place-content: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s ease-in-out;
  }
`

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export default CampaignImages

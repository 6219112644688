import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { Tooltip, DatePicker } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components/macro'

import { API_URL } from '../../../constants'

export const Home = () => {
  const { RangePicker } = DatePicker
  const [paymentDates, setPaymentDates] = useState(['2014-01-01', '2025-01-01'])
  const [imaiCredits, setImaiCredits] = useState()

  const { data: paymentsData, status: paymentsStatus } = useQuery(
    ['payment-summary', paymentDates],
    async () => {
      const { data } = await axios.get(`${API_URL}/admin-access/payment-summary`, {
        params: { fromDate: paymentDates[0], toDate: paymentDates[1] },
      })
      return data
    }
  )

  const fetchImaiCredits = async () => {
    const result = await axios
      .get('https://imai.co/api/account/info', {
        headers: {
          authkey: '9VFHeUcd5E',
        },
      })
      .catch(err => err)

    setImaiCredits(result?.data?.credits)
  }

  useEffect(() => {
    fetchImaiCredits()
  }, [])

  return (
    <Wrapper>
      <p className='imai'>
        IMAI Credits: <b>{imaiCredits || <LoadingOutlined />}</b>
      </p>

      <div className='payments-datepicker'>
        <h1>Payments</h1>
        <RangePicker
          onChange={(date, dateString) => {
            setPaymentDates(dateString)
          }}
        />
      </div>
      {paymentsStatus === 'success' && (
        <div className='payments'>
          <div className='summary'>
            <div className='item'>
              <span className='title'>
                Potentially Owed
                <Tooltip
                  arrow
                  title="How much is potentially owed to creators who have been activated but haven't completed all steps. This can potentially change as brands can still negotiate at this stage but shouldn't change too much.">
                  <InfoCircleOutlined style={{ fontSize: '0.8rem', marginLeft: '8px' }} />
                </Tooltip>
              </span>
              <div className='value'>${paymentsData.totalOwedActivated?.toLocaleString()}</div>
            </div>
            <div className='item'>
              <span className='title'>
                Currently Owed
                <Tooltip
                  arrow
                  title="How much is currently owed for completed orders but for some reason wasn't able to be paid out.">
                  <InfoCircleOutlined style={{ fontSize: '0.8rem', marginLeft: '8px' }} />
                </Tooltip>
              </span>
              <div className='value'>${paymentsData.totalOwedCompleted?.toLocaleString()}</div>
            </div>
            <div className='item'>
              <span className='title'>Total Incomplete Activated Opt-Ins</span>
              <div className='value'>{paymentsData.activatedOptIns.length?.toLocaleString()}</div>
            </div>
            <div className='item'>
              <span className='title'>
                Total Funds Loaded
                <Tooltip
                  arrow
                  title="How much is currently loaded into all of our brands' wallets.">
                  <InfoCircleOutlined style={{ fontSize: '0.8rem', marginLeft: '8px' }} />
                </Tooltip>
              </span>
              <div className='value'>${paymentsData.totalFundsLoaded?.toLocaleString()}</div>
            </div>
          </div>
          <h2>Incomplete Payments</h2>
          <div className='incomplete-payments'>
            {paymentsData.completedOptIns.map((optIn, key) => (
              <div key={key} className='opt-in'>
                <a
                  href={`https://admin.creator.co/opt-in/${optIn.id}`}
                  target='_blank'
                  rel='noreferrer'>
                  order #{optIn.id}
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.main`
  max-height: 100vh;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .payments-datepicker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 1.5rem;
    margin: 0;
  }
  h2 {
    font-size: 1.2rem;
  }
  .summary {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    margin-bottom: 30px;
  }
  .item {
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    padding: 10px;
    .title {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .value {
      color: #333;
    }
  }
  .payments {
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .incomplete-payments {
    max-height: 100%;
    overflow: auto;
  }
`

import { Alert, Button } from 'antd'
import { Formik, Form } from 'formik'
import { Input } from 'formik-antd'
import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'

import logo from '../../assets/images/logo.svg'
import { UserContext } from '../../contexts/UserContext'
import FormItem from '../utility/FormItem'

export const Login = () => {
  const { loginHandler } = useContext(UserContext)

  return !localStorage.getItem('token') ? (
    <Wrapper>
      <Formik
        initialValues={{}}
        onSubmit={async (data, { setStatus, setSubmitting }) => {
          setStatus('')
          const result = await loginHandler(data.email, data.password)
          if (result?.err) {
            setStatus(result.err)
            setSubmitting(false)
          }
        }}>
        {({ status, isSubmitting }) => (
          <Form className='login-container'>
            <img src={logo} alt='creator.co' className='logo' />
            <h2>Admin Dashboard</h2>
            <FormItem name='email' size='small'>
              <Input name='email' placeholder='Email' />
            </FormItem>
            <FormItem name='password' size='small'>
              <Input.Password name='password' placeholder='Password' />
            </FormItem>

            {!status || <Alert message={status} type='error' style={{ width: '100%' }} showIcon />}

            <Button loading={isSubmitting} htmlType='submit' type='primary'>
              Sign In
            </Button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  ) : (
    <Redirect to='/campaigns' />
  )
}

const Wrapper = styled.div`
  background: #f4f4f8;
  color: #23262f;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-container {
    background: #fff;
    box-shadow: 4px 8px 20px 4px rgba(154, 175, 193, 0.12);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    width: 90%;
    max-width: 400px;
    .ant-input-affix-wrapper {
      background: #f4f4f8;
      border-radius: 20px;
      padding: 0;
      border: none;
      .ant-input-suffix {
        position: absolute;
        right: 0;
        height: 100%;
        width: 30px;
      }
    }
    .ant-input {
      background: #f4f4f8;
      border: none;
      border-radius: 20px;
      padding: 10px 20px;
    }
  }
  .logo {
    height: 30px;
    margin-bottom: 10px;
  }
  h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  button {
    background: #23262f;
    border-color: #23262f;
    font-size: 1rem;
    height: 40px;
    width: 100%;
    margin-top: 20px;
    border-radius: 20px;
    &:hover {
      background: #027df0;
      border-color: #027df0;
    }
  }
`

import { CaretDownFilled, CaretUpFilled, SearchOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Empty, Spin } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input, Select } from 'formik-antd'
import React, { useState, useEffect, useContext, useRef } from 'react'
import { useQuery, useInfiniteQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { API_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import { Wrapper } from '../campaign/CampaignsList'
const { Option } = Select

const Brands = () => {
  // #region Constants
  const [brands, setBrands] = useState([])
  const [admins, setAdmins] = useState([])
  const [currentSortItem, setCurrentSortItem] = useState('id-desc')
  const [formData, setFormData] = useState({ sort: currentSortItem })
  const submitRef = useRef(0)
  const scrollRef = useRef()
  const { fetchCurrentUser } = useContext(UserContext)
  const specialists = admins.filter(admin => admin.assignedBrands?.length)
  // #endregion Constants

  // #region Queries
  const { data: currentUser } = useQuery('current-user', fetchCurrentUser)
  const selectedSpecialist = specialists.find(specialist => specialist.id === currentUser.id)?.id

  const fetchBrands = async ({ pageParam = 0 }) => {
    const { data } = await axios.get(`${API_URL}/brands?page=${pageParam}`, {
      params: formData,
    })
    return data
  }

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetchingMore, status } =
    useInfiniteQuery(['brands', formData], fetchBrands, {
      getNextPageParam: lastPage => lastPage.nextCursor,
    })
  // #endregion Queries

  // #region Effects
  useEffect(() => {
    const array = data?.pages.flatMap(page => page.brands) || []
    setBrands(array)
  }, [data, status])

  useEffect(() => {
    axios
      .get(`${API_URL}/admins`)
      .then(res => {
        setAdmins(res.data.administrators)
      })
      .catch(() => {
        setAdmins([])
      })
  }, [])
  // #endregion Effects

  // #region Functions
  const sortBy = sortItem => {
    const updatedSortItem =
      currentSortItem === `${sortItem}-desc` ? `${sortItem}-asc` : `${sortItem}-desc`
    setCurrentSortItem(updatedSortItem)
    setFormData({ ...formData, sort: updatedSortItem })
  }

  const handleSearch = data => {
    submitRef.current++
    const thisSubmit = submitRef.current
    setTimeout(() => {
      if (thisSubmit === submitRef.current) {
        setFormData(data)
      }
    }, 300)
  }

  const handleScroll = () => {
    const { scrollTop, offsetHeight, scrollHeight } = scrollRef.current
    const actualDistance = scrollHeight - (scrollTop + offsetHeight)
    if (actualDistance < 400 && !isFetchingMore && hasNextPage) {
      fetchNextPage()
    }
  }
  // #endregion Functions

  return (
    <Wrapper>
      <Formik initialValues={formData} onSubmit={handleSearch}>
        {({ setValues, submitForm }) => {
          return (
            <>
              <div className='header-wrapper'>
                <h1>Brands</h1>
                {status === 'success' ? (
                  <span className='num-results'>
                    {brands?.length} / {data?.pages[0]?.totalResults?.toLocaleString() || 0} results
                  </span>
                ) : (
                  <LoadingOutlined spin />
                )}
                <div className='header-buttons'>
                  {selectedSpecialist && (
                    <div className='filter'>
                      <Button
                        type='primary'
                        onClick={() => {
                          setValues(prev => ({ specialistId: selectedSpecialist, ...prev }))
                          submitForm()
                        }}>
                        My Brands
                      </Button>
                    </div>
                  )}
                </div>
              </div>

              <Form className='filters'>
                <Input
                  name='search'
                  placeholder='Search brands'
                  prefix={<SearchOutlined />}
                  onChange={submitForm}
                  allowClear
                  style={{ width: 'auto' }}
                />
                <Select
                  placeholder='Specialist'
                  name='specialistId'
                  onChange={submitForm}
                  allowClear>
                  {specialists.map(specialist => (
                    <Option
                      key={specialist.id}
                      value={
                        specialist.id
                      }>{`${specialist.firstName} ${specialist.lastName}`}</Option>
                  ))}
                </Select>
              </Form>
            </>
          )
        }}
      </Formik>

      <div className='list-inner' onScroll={handleScroll} ref={scrollRef}>
        <div className='list-header brands'>
          <div
            className='id sortable'
            onClick={() => sortBy('id')}
            onKeyDown={() => sortBy('id')}
            tabIndex={0}
            role='button'>
            ID
            <div className='sort-arrows'>
              <span className={`sort-arrow ${currentSortItem === 'id-desc' && 'current'}`}>
                <CaretUpFilled />
              </span>
              <span className={`sort-arrow ${currentSortItem === 'id-asc' && 'current'}`}>
                <CaretDownFilled />
              </span>
            </div>
          </div>
          <div
            className='brand sortable'
            onClick={() => {
              sortBy('name')
            }}
            onKeyDown={() => sortBy('name')}
            tabIndex={0}
            role='button'>
            Name
            <div className='sort-arrows'>
              <span className={`sort-arrow ${currentSortItem === 'name-desc' && 'current'}`}>
                <CaretUpFilled />
              </span>
              <span className={`sort-arrow ${currentSortItem === 'name-asc' && 'current'}`}>
                <CaretDownFilled />
              </span>
            </div>
          </div>
          <div className='specialist'>Specialist</div>
          <div className='num-campaigns'>Active Campaigns</div>
          <div className='num-campaigns'>Pending Campaigns</div>
        </div>
        {status === 'success' &&
          (brands?.length ? (
            <>
              {brands?.map(brand => {
                const activeCampaigns = brand.campaigns.filter(
                  campaign => campaign.status === 'publish'
                ).length
                const pendingCampaigns = brand.campaigns.filter(
                  campaign => campaign.status === 'pending'
                ).length

                return (
                  <Link className='list-item brands' key={brand.id} to={`/brand/${brand.id}`}>
                    <div className='id'>#{brand.id}</div>
                    <div className='brand'>{brand.name}</div>
                    <div className={`specialist ${!brand.dedicatedSpecialist && 'none'}`}>
                      {brand.dedicatedSpecialist
                        ? `${brand.dedicatedSpecialist.firstName} ${brand.dedicatedSpecialist.lastName}`
                        : 'None'}
                    </div>
                    <div className={`num-campaigns ${!activeCampaigns && 'none'}`}>
                      {activeCampaigns}
                    </div>
                    <div className={`num-campaigns ${!pendingCampaigns && 'none'}`}>
                      {pendingCampaigns}
                    </div>
                  </Link>
                )
              })}
              {isFetchingNextPage && (
                <div className='loading'>
                  <Spin />
                </div>
              )}
            </>
          ) : (
            <div className='no-results'>
              <Empty description='No brands found.' />
            </div>
          ))}

        {status === 'loading' && (
          <div className='loading'>
            <Spin />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default Brands

import {
  ArrowLeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleFilled,
  SaveOutlined,
  StopOutlined,
} from '@ant-design/icons'
import {
  Alert,
  Button,
  Col,
  Collapse,
  Empty,
  Modal,
  Row,
  Spin,
  Tag,
  Tooltip,
  message,
  Checkbox as AntCheckbox,
  Popover,
} from 'antd'
import { CountryPhoneInput, ConfigProvider } from 'antd-country-phone-input'
import axios from 'axios'
import { Formik } from 'formik'
import { DatePicker, Form, Input, Select, Checkbox } from 'formik-antd'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useQuery, useQueryClient } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import en from 'world_countries_lists/data/countries/en/world.json'
import * as yup from 'yup'

import instagram from '../../../../assets/images/instagram-color-square.svg'
import tiktok from '../../../../assets/images/tiktok-color-square.svg'
import youtube from '../../../../assets/images/youtube-color-square.svg'
import { API_URL } from '../../../../constants'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import { NotFound } from '../../../general/NotFound'
import FormItem from '../../../utility/FormItem'
const socialIcons = { instagram, tiktok, youtube }

// destructure components
const { Option } = Select
const { Panel } = Collapse
const CheckboxGroup = Checkbox.Group

const UserPage = () => {
  // #region Constants
  const { id } = useParams()
  const queryClient = new useQueryClient()
  const { getCategories } = useContext(GlobalContext)
  const [addToBrandVisible, setAddToBrandVisible] = useState(false)
  const [removeBrandVisible, setRemoveBrandVisible] = useState(false)
  const [states, setStates] = useState([])
  const [countries, setCountries] = useState([])
  const [allBrands, setAllBrands] = useState([])
  const [fetchingBrands, setFetchingBrands] = useState(true)
  const brandFetch = useRef(0)
  const [copied, setCopied] = useState(false)
  const [removeBrandLoading, setRemoveBrandLoading] = useState(false)
  const [brandUsersLoading, setBrandUsersLoading] = useState([])
  const platforms = ['instagram', 'tiktok', 'youtube']
  const [checkingConnection, setCheckingConnection] = useState(null)
  // #endregion Constants

  // #region Queries
  const { data: user, status } = useQuery(['user', id], async () => {
    try {
      const { data } = await axios.get(`${API_URL}/admin/user/${id}`)
      return data
    } catch (err) {
      if (err.response.data?.err) return null
    }
  })

  const { data: categories } = useQuery('categories', getCategories)
  // #endregion Queries

  // #region Effects
  useEffect(() => {
    axios
      .get('../json/states.json')
      .then(res => {
        setStates(res.data.states)
      })
      .catch(() => {
        setStates([])
      })
  }, [])

  useEffect(() => {
    axios
      .get('../json/countries.json')
      .then(res => {
        setCountries(res.data.countries)
      })
      .catch(() => {
        setCountries([])
      })
  }, [])

  useEffect(() => {
    axios
      .get(`${API_URL}/brands`, {
        params: { sort: 'campaigns-desc' },
      })
      .then(res => {
        setAllBrands(res.data.brands)
      })
      .catch(() => {
        setAllBrands([])
      })
      .finally(() => {
        setFetchingBrands(false)
      })
  }, [])
  // #endregion Effects

  // #region Functions
  const handleSearchBrands = search => {
    brandFetch.current++
    const fetchId = brandFetch.current
    setFetchingBrands(true)
    axios
      .get(`${API_URL}/brands`, {
        params: { search, sort: 'campaigns-desc' },
      })
      .then(res => {
        if (brandFetch.current === fetchId) {
          setAllBrands(res.data.brands)
        }
      })
      .catch(() => {
        if (brandFetch.current === fetchId) {
          setAllBrands([])
        }
      })
      .finally(() => {
        if (brandFetch.current === fetchId) {
          setFetchingBrands(false)
        }
      })
  }

  const formatPlatformText = platform => {
    if (platform === 'instagram') return 'Instagram'
    if (platform === 'tiktok') return 'TikTok'
    if (platform === 'youtube') return 'YouTube'
  }

  const checkConnection = async platform => {
    const socialProfile = user.socialProfiles?.find(profile => profile.platform === platform)
    const accountId = socialProfile?.phylloData?.account?.id
    setCheckingConnection(platform)
    try {
      const res = await axios.get(`${API_URL}/phyllo-account/${accountId}`)
      if (res.data.status === 'CONNECTED') {
        message.success(`Connected to ${formatPlatformText(platform)}`)
      } else {
        message.error(`Not connected to ${formatPlatformText(platform)}`)
      }
    } catch (err) {
      message.error('Error fetching connection status')
    }
    setCheckingConnection(null)
  }

  const updateUser = async values => {
    try {
      await axios.put(`${API_URL}/admin/user/${id}`, values)
      message.success('User Updated')
      queryClient.invalidateQueries(['user', id])
    } catch (err) {
      message.error(err.response.data?.err || 'Sorry, something went wrong.')
    }
  }

  const addUserToBrand = async values => {
    const { brandId } = values
    try {
      await axios.post(`${API_URL}/brand/${brandId}/user/${user.id}`)
      setAddToBrandVisible(false)
      message.success('User added to brand')
      queryClient.invalidateQueries(['user', id])
    } catch (err) {
      message.error(`${err.response.data?.err}${err.response.data?.errCode}`)
    }
  }

  const removeUserFromBrand = async () => {
    setRemoveBrandLoading(true)
    const brandId = removeBrandVisible
    try {
      await axios.delete(`${API_URL}/brand/${brandId}/user/${user.id}`)
      setRemoveBrandLoading(false)
      setRemoveBrandVisible(false)
      message.success('User removed from brand')
      queryClient.invalidateQueries(['user', id])
    } catch (err) {
      message.error(`${err.response.data?.err}${err.response.data?.errCode}`)
    }
  }

  const updateBrandProfile = (brandId, data) => {
    setBrandUsersLoading(prev => [...prev, brandId])
    axios
      .put(`${API_URL}/brand/${brandId}/user/${user.id}`, data)
      .then(() => {
        message.success('Brand User Updated')
        queryClient.invalidateQueries(['user', id])
      })
      .catch(() => {
        message.error('Something went wrong updating the brand user.')
      })
      .finally(() => {
        setBrandUsersLoading(prev => prev.filter(id => id !== brandId))
      })
  }
  // #endregion Functions

  return (
    <Wrapper>
      {status === 'success' &&
        (user ? (
          <>
            <div className='header'>
              <Link to='/users' className='back-btn'>
                <ArrowLeftOutlined /> All Users
              </Link>

              <div className='header-group'>
                <h1>{`${user.firstName} ${user.lastName}`}</h1>
              </div>

              <div className='id-wrapper'>
                User
                <CopyToClipboard
                  text={user.id}
                  onCopy={() => {
                    setCopied(user.id)
                    setTimeout(() => setCopied(false), 1000)
                  }}>
                  <Tooltip open={copied === user.id} title='Copied'>
                    <span className='copy-id'>#{user.id}</span>
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </div>

            <div className='sections'>
              <Formik
                initialValues={{
                  firstName: user.firstName || '',
                  lastName: user.lastName || '',
                  email: user.email || '',
                  role: user.role,
                  birthDate: user.creatorProfile?.birthDate || '',
                  gender: user.creatorProfile?.gender || '',
                  niches: user.creatorProfile?.niches?.map(({ category }) => category.id),
                  shippingAddress1: user?.creatorProfile?.shippingAddress1 || '',
                  shippingAddress2: user?.creatorProfile?.shippingAddress2 || '',
                  shippingCity: user?.creatorProfile?.shippingCity || '',
                  shippingRegion: user?.creatorProfile?.shippingRegion || '',
                  shippingCountry: user?.creatorProfile?.shippingCountry || '',
                  shippingPostcode: user?.creatorProfile?.shippingPostcode || '',
                  phone: user?.phone || '',
                  phoneShort: user?.phoneShort || 'US',
                  phoneCode: user?.phoneCode || 1,
                  emailOptOuts: user?.extraData?.emailOptOuts || [],
                }}
                validationSchema={yup.object().shape({
                  firstName: yup
                    .string()
                    .required('First name is required')
                    .min(2, 'Please enter at least 2 characters'),
                  lastName: yup
                    .string()
                    .required('Last name is required')
                    .min(2, 'Please enter at least 2 characters'),
                  email: yup
                    .string()
                    .email('Please enter a valid email address')
                    .required('Email is required'),
                })}
                onSubmit={updateUser}>
                {({ status, values, setValues, isSubmitting }) => (
                  <Form>
                    <section>
                      <div className='section-header'>
                        <h2>Profile Settings</h2>
                        <Button
                          type='primary'
                          icon={<SaveOutlined />}
                          htmlType='submit'
                          loading={isSubmitting}>
                          Save
                        </Button>
                      </div>

                      {status && <Alert message={status} type='error' showIcon />}

                      {/* ACCOUNT SETTINGS */}
                      <FormItem name='role' label='Role'>
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          virtual={false}
                          name='role'
                          showArrow
                          style={{ width: '100%' }}>
                          <Option value='creator'>
                            <Tag color='purple'>Creator</Tag>
                          </Option>
                          <Option value='brand'>
                            <Tag color='blue'>Brand</Tag>
                          </Option>
                          <Option value='administrator'>
                            <Tag color='green'>Admin - Full Access</Tag>
                          </Option>
                        </Select>
                      </FormItem>

                      <Row gutter={16}>
                        <Col span={12}>
                          <FormItem name='firstName' label='First Name'>
                            <Input name='firstName' />
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem name='lastName' label='Last Name'>
                            <Input name='lastName' />
                          </FormItem>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={12}>
                          <FormItem name='email' label='Email'>
                            <Input name='email' />
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <ConfigProvider locale={en}>
                            <FormItem name='phone' size='small' label='Phone'>
                              <CountryPhoneInput
                                inline
                                autoComplete='phone'
                                name='phone'
                                value={{
                                  phone: values.phone,
                                  short: values.phoneShort,
                                  code: values.phoneCode,
                                }}
                                placeholder='Phone number'
                                onChange={ev => {
                                  setValues(prev => ({
                                    ...prev,
                                    phone: ev.phone,
                                    phoneShort: ev.short,
                                    phoneCode: ev.code,
                                  }))
                                }}
                              />
                            </FormItem>
                          </ConfigProvider>
                        </Col>
                      </Row>

                      {user.role !== 'brand' && (
                        <>
                          {/* SHIPPING ADDRESS */}
                          <Row gutter={16}>
                            <Col span={12}>
                              <FormItem name='shippingAddress1' label='Street Address'>
                                <Input name='shippingAddress1' />
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem name='shippingAddress2' label='Apt / Suite / Unit'>
                                <Input name='shippingAddress2' placeholder='Optional' />
                              </FormItem>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={12}>
                              <FormItem label='Country' name='shippingCountry'>
                                <Select
                                  getPopupContainer={trigger => trigger.parentNode}
                                  virtual={false}
                                  name='shippingCountry'
                                  showSearch
                                  autoComplete='none'
                                  style={{ width: '100%' }}
                                  listHeight={256}
                                  defaultValue={values.shippingCountry}
                                  optionFilterProp='label'
                                  onChange={e => {
                                    setValues({ ...values, shippingCountry: e })
                                  }}>
                                  {countries.map(country => (
                                    <Option
                                      key={country.id}
                                      value={country.country_code}
                                      label={country.name}>
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem label='State / Province' name='shippingRegion'>
                                <Select
                                  getPopupContainer={trigger => trigger.parentNode}
                                  virtual={false}
                                  name='shippingRegion'
                                  showSearch
                                  required={true}
                                  autoComplete='none'
                                  style={{ width: '100%' }}
                                  listHeight={256}
                                  defaultValue={values.shippingRegion}
                                  optionFilterProp='label'
                                  onChange={e => {
                                    setValues({ ...values, shippingRegion: e })
                                  }}>
                                  {states
                                    .filter(state => state.country_code === values.shippingCountry)
                                    .map(state => (
                                      <Option key={state.id} value={state.name} label={state.name}>
                                        {state.name}
                                      </Option>
                                    ))}
                                </Select>
                              </FormItem>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={12}>
                              <FormItem name='shippingCity' label='City'>
                                <Input name='shippingCity' />
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem name='shippingPostcode' label='Postcode'>
                                <Input name='shippingPostcode' />
                              </FormItem>
                            </Col>
                          </Row>

                          {/* MISCELLANEOUS INFO */}
                          <Row gutter={16}>
                            <Col span={12}>
                              <FormItem label='Date of birth' name='birthDate'>
                                <DatePicker
                                  getPopupContainer={trigger => trigger.parentNode}
                                  format='MMMM D, Y'
                                  name='birthDate'
                                  allowClear={false}
                                  style={{ width: '100%' }}
                                  disabledDate={current => current.isAfter(moment())}
                                  defaultValue={
                                    values.birthDate ? moment(values.birthDate) : undefined
                                  }
                                  onChange={e => {
                                    setValues({ ...values, birthDate: e?.toISOString() })
                                  }}
                                />
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem label='Gender' name='gender'>
                                <Select
                                  getPopupContainer={trigger => trigger.parentNode}
                                  virtual={false}
                                  name='gender'
                                  showArrow
                                  allowClear
                                  optionFilterProp='label'
                                  style={{ width: '100%' }}
                                  defaultValue={values.gender || ''}
                                  onChange={e => {
                                    setValues(prev => ({ ...prev, gender: e || '' }))
                                  }}>
                                  <Option value='female'>Female</Option>
                                  <Option value='male'>Male</Option>
                                  <Option value='non-binary'>Non-binary</Option>
                                </Select>
                              </FormItem>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={12}>
                              <FormItem
                                label='Niches'
                                sublabel='(areas of influence)'
                                name='niches'>
                                <Select
                                  getPopupContainer={trigger => trigger.parentNode}
                                  virtual={false}
                                  name='niches'
                                  showArrow
                                  optionFilterProp='label'
                                  filterOption
                                  mode='multiple'
                                  style={{ width: '100%' }}
                                  loading={!categories?.length}
                                  defaultValue={user.creatorProfile?.niches?.map(
                                    ({ category }) => category.id
                                  )}
                                  onChange={e => {
                                    setValues(prev => ({ ...prev, niches: e }))
                                  }}>
                                  {categories?.map(category => (
                                    <Option
                                      label={category.title}
                                      key={category.id}
                                      value={category.id}>
                                      {category.title}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </Col>
                          </Row>
                        </>
                      )}

                      {(user.role === 'administrator' || user.role === 'brand') && (
                        <FormItem name='emailOptOuts' label='Email Opt Outs'>
                          <CheckboxGroup
                            name='emailOptOuts'
                            options={[
                              { label: 'Creator Opt In', value: 'creator_opt_in' },
                              { label: 'Search Invite', value: 'search_invite' },
                              { label: 'Direct Chat Message', value: 'chat_message' },
                              { label: 'File Upload', value: 'file_upload' },
                            ]}
                            defaultValue={values.emailOptOuts}
                            onChange={e => {
                              setValues({ ...values, emailOptOuts: e })
                            }}
                          />
                        </FormItem>
                      )}
                    </section>
                  </Form>
                )}
              </Formik>

              {/* SOCIAL ACCOUNTS */}
              <section>
                <div className='section-header'>
                  <h2>Social Accounts</h2>
                </div>
                <div className='id-wrapper'>
                  Phyllo User ID:
                  {user.creatorProfile?.phylloUserId ? (
                    <CopyToClipboard
                      text={user.creatorProfile?.phylloUserId}
                      onCopy={() => {
                        setCopied(user.creatorProfile?.phylloUserId)
                        setTimeout(() => setCopied(false), 1000)
                      }}>
                      <Tooltip open={copied === user.creatorProfile?.phylloUserId} title='Copied'>
                        <span className='copy-id'>#{user.creatorProfile?.phylloUserId}</span>
                      </Tooltip>
                    </CopyToClipboard>
                  ) : (
                    <span className='copy-id none'>N/A</span>
                  )}
                </div>
                <Collapse bordered={false} accordion={true}>
                  {platforms.map(platform => {
                    const socialProfile = user.socialProfiles?.find(
                      profile => profile.platform === platform
                    )

                    return (
                      <Panel
                        header={
                          <span className='panel-header'>
                            <img className='social-icon' src={socialIcons[platform]} alt='' />
                            {formatPlatformText(platform)}
                          </span>
                        }
                        extra={
                          <Button
                            type='link'
                            disabled={!socialProfile}
                            loading={checkingConnection === platform}
                            onClick={() => {
                              checkConnection(platform)
                            }}>
                            {socialProfile ? 'Check Status' : 'Not Connected'}
                          </Button>
                        }
                        key={platform}
                        collapsible={!socialProfile ? 'disabled' : 'icon'}>
                        {socialProfile && (
                          <div className='social-profile'>
                            <pre>{JSON.stringify(socialProfile, null, 2)}</pre>
                          </div>
                        )}
                      </Panel>
                    )
                  })}
                </Collapse>
              </section>

              {/* TOTAL PAYMENTS */}
              <section>
                <div className='section-header'>
                  <h2>Payments</h2>
                </div>
                <p>Total Paid: ${user.totalPaid}</p>

                <p>
                  Trolley Recipient Status:{' '}
                  <Tag color={user.trolleyStatus === 'active' ? 'green' : 'red'}>
                    {user.trolleyStatus || 'Not Connected'}
                  </Tag>
                </p>
              </section>

              {/* BRAND PROFILES */}
              {user.role !== 'creator' && (
                <section>
                  <div className='section-header'>
                    <h2>Brands</h2>
                    <Button
                      onClick={() => setAddToBrandVisible(true)}
                      icon={<PlusOutlined />}
                      type='primary'>
                      Add
                    </Button>
                  </div>
                  {user.brandProfiles?.length ? (
                    <table>
                      <thead>
                        <tr>
                          <th>Brand ID</th>
                          <th>Brand Name</th>
                          <th>
                            <Popover content='Give this user agency privileges on selected brands'>
                              Agency <QuestionCircleFilled />
                            </Popover>
                          </th>
                          <th></th>
                        </tr>
                      </thead>

                      {user.brandProfiles.map(({ brand, agencyPrivileges }, i) => {
                        return (
                          <tbody key={i}>
                            <tr>
                              <td>#{brand.id}</td>
                              <td>
                                <Link to={`/brand/${brand.id}`} key={brand.id}>
                                  {brand.name}
                                </Link>
                              </td>
                              <td>
                                {!brandUsersLoading.includes(brand.id) ? (
                                  <AntCheckbox
                                    checked={agencyPrivileges}
                                    loading={brandUsersLoading.includes(brand.id)}
                                    onChange={e =>
                                      updateBrandProfile(brand.id, {
                                        agencyPrivileges: e.target.checked,
                                      })
                                    }
                                  />
                                ) : (
                                  <LoadingOutlined spin />
                                )}
                              </td>
                              <td className='end'>
                                <Button
                                  className='remove-btn'
                                  type='link'
                                  danger
                                  onClick={() => setRemoveBrandVisible(brand.id)}>
                                  <StopOutlined /> Remove
                                </Button>
                                <Modal
                                  title='Remove from Brand'
                                  open={removeBrandVisible === brand.id}
                                  destroyOnClose
                                  okText='Remove'
                                  onOk={removeUserFromBrand}
                                  okButtonProps={{ danger: true }}
                                  confirmLoading={removeBrandLoading}
                                  onCancel={() => setRemoveBrandVisible(false)}>
                                  <p>
                                    Are you sure you want to remove {user.firstName} from{' '}
                                    {brand.name}?
                                  </p>
                                </Modal>
                              </td>
                            </tr>
                          </tbody>
                        )
                      })}
                    </table>
                  ) : (
                    <Empty description='No brands.' />
                  )}
                </section>
              )}

              {/* OPT-INS  */}
              {user.role !== 'brand' && (
                <section>
                  <div className='section-header'>
                    <h2>Opt-Ins</h2>
                  </div>
                  {user.optIns?.length ? (
                    <table>
                      <thead>
                        <tr>
                          <th>Opt-In ID</th>
                          <th>Campaign Name</th>
                          <th>Status</th>
                          <th>ShoutOut Score</th>
                        </tr>
                      </thead>
                      {user.optIns.map((optIn, i) => {
                        return (
                          <tbody key={i}>
                            <tr>
                              <td>#{optIn.id}</td>
                              <td className='campaign-title'>
                                <Link to={`/opt-in/${optIn.id}`} key={optIn.campaignId}>
                                  {optIn.campaign.title}
                                </Link>
                              </td>
                              <td className={`status ${optIn.status}`}>{optIn.status}</td>
                              <td>
                                {optIn.extraData.shoutoutScore
                                  ? optIn.extraData.shoutoutScore
                                  : 'N/A'}
                              </td>
                            </tr>
                          </tbody>
                        )
                      })}
                    </table>
                  ) : (
                    <Empty description='No Opt-Ins.' />
                  )}
                </section>
              )}

              <section>
                <div className='section-header'>
                  <h2>Identity (Cognito)</h2>
                </div>
                {!!user.idmUser && (
                  <>
                    <h3>User</h3>
                    <pre>{JSON.stringify(user.idmUser?.user?.[0], null, 2)}</pre>

                    <h3>Brand</h3>
                    <pre>{JSON.stringify(user.idmUserBrands, null, 2)}</pre>

                    <h3>Agency</h3>
                    <pre>{JSON.stringify(user.idmUserAgencies, null, 2)}</pre>
                  </>
                )}
              </section>
            </div>

            <Formik initialValues={{}} onSubmit={addUserToBrand}>
              {({ isSubmitting, submitForm }) => (
                <Form>
                  <Modal
                    title='Add User to Brand'
                    destroyOnClose
                    onCancel={() => setAddToBrandVisible(false)}
                    open={addToBrandVisible}
                    okText='Add'
                    onOk={submitForm}
                    confirmLoading={isSubmitting}>
                    <FormItem label='Brand'>
                      <Select
                        required
                        style={{ width: '100%' }}
                        name='brandId'
                        loading={fetchingBrands}
                        placeholder='Search Brands'
                        showSearch
                        filterOption={false}
                        onSearch={val => handleSearchBrands(val)}>
                        {allBrands.map(item => (
                          <Select.Option label={item.name} key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Modal>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <NotFound message='User not found.' buttonText='Go Back' to='/users' />
        ))}

      {status === 'loading' && (
        <div className='loading'>
          <Spin /> Loading...
        </div>
      )}
    </Wrapper>
  )
}

export const Wrapper = styled.main`
  background: #f4f4f8;
  min-height: 100%;
  max-height: calc(100vh - 50px);
  overflow: hidden;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  .header {
    background: #f4f4f8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    box-shadow: 0px 10px 10px -10px #00000036;
    padding: 12px 20px;
    white-space: nowrap;
    z-index: 1;
  }
  .header-group {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  h1 {
    font-size: 20px;
    // max 1 line
    overflow: hidden;
    text-overflow: ellipsis;
    .grey {
      color: ${props => props.theme.crcoGrey};
    }
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 1rem;
  }
  h1,
  h2,
  h3 {
    margin: 0;
  }
  .ant-tag {
    text-transform: uppercase;
    padding-top: 3px;
    border-radius: 5px;
  }
  form {
    display: flex;
    flex-direction: column;
    &.page-inner {
      max-height: 100%;
      overflow: auto;
    }
  }
  .sections {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-height: 100%;
    overflow: auto;
  }
  section {
    background: #fff;
    width: 100%;
    max-width: 800px;
    border-radius: 10px;
    padding: 20px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
  }
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(230, 230, 230);
    padding-bottom: 5px;
  }
  .section-inner-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &:not(:last-child) {
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 20px;
    }
    p {
      margin: 0;
    }
  }
  .ant-empty {
    padding: 20px;
  }
  .copy-id {
    cursor: pointer;
    color: ${props => props.theme.crcoGrey};
    transition: 0.2s ease-in-out;
    &:hover {
      color: ${props => props.theme.crcoMidnight};
    }
    &.none {
      pointer-events: none;
    }
  }
  table {
    width: 100%;
    text-align: left;
    tr {
      border-bottom: 1px solid #e8e8e8;
    }
    th {
      color: ${props => props.theme.crcoGrey};
      text-transform: uppercase;
      font-size: 0.8rem;
      padding: 5px;
    }
    td {
      padding: 5px;
    }
    .end {
      display: flex;
    }
    .remove-btn {
      margin-left: auto;
    }
  }

  .campaign-title {
    max-width: 300px;
  }

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    list-style-type: none;
  }

  .input-btn-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .antd-country-phone-input {
    padding: 0px;
  }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px 0;
  }
  .ant-collapse {
    border-radius: 5px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }
  .panel-header {
    display: flex;
    align-items: center;
    gap: 5px;
    .social-icon {
      height: 20px;
      width: 20px;
    }
  }
  .ant-collapse-content-box {
    border-top: 1px solid #e8e8e8;
    color: ${props => props.theme.crcoGrey};
    font-size: 0.8rem;
  }

  .loading {
    flex: 1;
    display: grid;
    place-content: center;
    color: ${props => props.theme.crcoGrey};
  }

  .capitalize {
    text-transform: capitalize;
  }

  // OPT-IN PAGE
  .id-wrapper {
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 7px;
    p {
      margin: 0;
    }
  }
  .status {
    font-size: 0.9rem;
    text-transform: capitalize;
    &.publish,
    &.activated {
      color: ${props => props.theme.crcoTechBlue};
    }
    &.completed {
      color: #22b162;
    }
    &.pending {
      color: #ff7500;
    }
    &.paused {
      color: #999;
    }
    &.draft {
      color: #ccc;
    }
    &.cancelled {
      color: red;
    }
  }
`

export default UserPage

import axios from 'axios'
import { InputNumber, Select, Checkbox, Input } from 'formik-antd'
import { useState, useEffect, useContext } from 'react'
import { useQuery } from 'react-query'

import instagram from '../../../../assets/images/instagram-color-square.svg'
import tiktok from '../../../../assets/images/tiktok-color-square.svg'
import youtube from '../../../../assets/images/youtube-color-square.svg'
import { API_URL } from '../../../../constants'
import { GlobalContext } from '../../../../contexts/GlobalContext'
import { UserContext } from '../../../../contexts/UserContext'
import FormItem from '../../../utility/FormItem'
const { Option } = Select

export const CampaignRequirements = ({ values, setValues }) => {
  const { token } = useContext(UserContext)
  const { getCategories } = useContext(GlobalContext)

  const { data: categories } = useQuery('categories', getCategories)

  const [countries, setCountries] = useState([])

  useEffect(() => {
    fetchCountries('')
  }, [])

  const fetchCountries = val => {
    setCountries([])
    axios
      .get(`${API_URL}/countries/?search=${val}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setCountries(
          res.data.map(country => ({
            text: country.countryName,
            value: country.countryCode,
          }))
        )
      })
  }

  return (
    <div>
      <FormItem>
        <Checkbox name='disableQualifications'>Disable opt-in requirements</Checkbox>
      </FormItem>

      <div className='section-wrapper'>
        <FormItem label='Social Channel(s)'>
          <Select
            name='socialChannels'
            placeholder='Select'
            mode='multiple'
            allowClear
            showArrow
            style={{ width: '100%' }}
            onChange={e => {
              setValues({
                ...values,
                socialChannels: e,
                contentFormats: [],
                contentFormatCounts: {},
              })
            }}>
            <Option value='instagram'>
              <img src={instagram} alt='' style={{ height: '20px', marginRight: '8px' }} />
              Instagram
            </Option>
            <Option value='tiktok'>
              <img src={tiktok} alt='' style={{ height: '20px', marginRight: '8px' }} />
              TikTok
            </Option>
            <Option value='youtube'>
              <img src={youtube} alt='' style={{ height: '20px', marginRight: '8px' }} />
              YouTube
            </Option>
          </Select>
        </FormItem>

        {values.socialChannels?.map(channel => (
          <>
            <h4>
              {channel === 'instagram'
                ? 'Instagram'
                : channel === 'tiktok'
                  ? 'TikTok'
                  : channel === 'youtube' && 'YouTube'}
            </h4>
            <div className='form-row'>
              <FormItem
                label={`${channel === 'youtube' ? 'Subscribers' : 'Followers'}`}
                name={`${channel}Reach`}
                size='small'>
                <InputNumber
                  name={`${channel}Reach`}
                  formatter={value => (value ? `${value}+` : '')}
                  parser={value => value.replace('+', '')}
                />
              </FormItem>
              <FormItem label='Engagement' name={`${channel}Engagement`} size='small'>
                <InputNumber
                  name={`${channel}Engagement`}
                  formatter={value => (value ? `${value}%+` : '')}
                  parser={value => value.replace('%+', '')}
                />
              </FormItem>
            </div>
          </>
        ))}
      </div>

      <div className='section-wrapper'>
        <FormItem label='Residence' name='countries'>
          <Select
            name='countries'
            mode='multiple'
            filterOption={false}
            placeholder='Select countries'
            onSearch={fetchCountries}>
            {countries.map(country => (
              <Option key={country.value} value={country.value}>
                {country.text}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem>
          <Checkbox name='requiresShipping'>Require shipping</Checkbox>
        </FormItem>

        <div className='form-row'>
          <FormItem label='Min. Age'>
            <InputNumber name='minAge' placeholder='Min' />
          </FormItem>
          <FormItem label='Max. Age'>
            <InputNumber name='maxAge' placeholder='Max' />
          </FormItem>
        </div>

        <FormItem label='Genders'>
          <Select
            mode='multiple'
            name='genders'
            allowClear
            showArrow
            placeholder='Select'
            style={{ width: '100%' }}
            options={[
              { label: 'Male', value: 'male' },
              { label: 'Female', value: 'female' },
              { label: 'Non Binary', value: 'non-binary' },
            ]}>
            {values?.genders?.map(gender => (
              <Option key={gender} value={gender}>
                {gender}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem label='Niches' sublabel='(areas of influence)' name='interests'>
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            virtual={false}
            name='interests'
            showArrow
            optionFilterProp='label'
            placeholder='Select'
            filterOption
            allowClear
            mode='multiple'
            loading={!categories?.length}
            style={{ width: '100%' }}>
            {categories?.map(category => (
              <Option label={category.title} key={category.id} value={category.slug}>
                {category.title}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem label='Profession' sublabel='(for bespoke campaigns)'>
          <Input name='creatorProfession' disabled={values.strategy !== 'bespoke'} />
        </FormItem>
      </div>
    </div>
  )
}

import axios from 'axios'
import { Input, Select, Checkbox, DatePicker } from 'formik-antd'
import React, { useState, useRef, useEffect } from 'react'

import { API_URL } from '../../../../constants'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import FormItem from '../../../utility/FormItem'

const { Option } = Select

export const CampaignGeneral = ({ values, campaign }) => {
  const [allBrands, setAllBrands] = useState([])
  const [fetchingBrands, setFetchingBrands] = useState(true)
  const brandFetch = useRef(0)

  useEffect(() => {
    axios
      .get(`${API_URL}/brands`, {
        params: { sort: 'campaigns-desc' },
      })
      .then(res => {
        setAllBrands(res.data.brands)
      })
      .catch(() => {
        setAllBrands([])
      })
      .finally(() => {
        setFetchingBrands(false)
      })
  }, [])

  const handleSearchBrands = search => {
    brandFetch.current++
    const fetchId = brandFetch.current
    setFetchingBrands(true)
    axios
      .get(`${API_URL}/brands`, {
        params: { search, sort: 'campaigns-desc' },
      })
      .then(res => {
        if (brandFetch.current === fetchId) {
          setAllBrands(res.data.brands)
        }
      })
      .catch(() => {
        if (brandFetch.current === fetchId) {
          setAllBrands([])
        }
      })
      .finally(() => {
        if (brandFetch.current === fetchId) {
          setFetchingBrands(false)
        }
      })
  }

  return (
    <>
      <FormItem>
        <Checkbox name='disableActivations'>Enable automatic activations</Checkbox>
      </FormItem>

      <div className='section-wrapper'>
        <FormItem label='Brand'>
          <Select
            required
            style={{ width: '100%' }}
            name='brandId'
            loading={fetchingBrands}
            placeholder='Search Brands'
            showSearch
            filterOption={false}
            onSearch={val => handleSearchBrands(val)}>
            {allBrands.map(item => (
              <Select.Option label={item.name} key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <FormItem label='Strategy'>
          <Select name='strategy'>
            <Option value='ugc'>UGC</Option>
            <Option value='shoutout'>ShoutOut</Option>
            <Option value='giveaway'>Giveaway</Option>
            <Option value='affiliate'>Affiliate</Option>
            <Option value='bespoke'>Bespoke</Option>
          </Select>
        </FormItem>

        <FormItem label='Campaign Description'>
          <Input.TextArea name='description' autoSize={{ minRows: 4, maxRows: 10 }} />
        </FormItem>

        <FormItem label='Awin Link'>
          <Input name='awinLink' />
        </FormItem>

        <FormItem label='Date Created'>
          <DatePicker disabled defaultValue={campaign.date} format='MMMM D, Y' />
        </FormItem>

        <FormItem label='Date Published'>
          <DatePicker name='publishDate' format='MMMM D, Y' />
        </FormItem>

        <FormItem>
          <Checkbox name='unlisted'>Unlisted</Checkbox>
        </FormItem>

        <FormItem>
          <Checkbox name='disableTwilio'>Disable Twilio</Checkbox>
        </FormItem>

        {values.unlisted && (
          <FormItem label='Password'>
            <Input name='password' />
          </FormItem>
        )}
      </div>
    </>
  )
}

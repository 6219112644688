import axios from 'axios'
import React, { createContext, useState, useEffect } from 'react'

import { API_URL } from '../constants'

export const UserContext = createContext(null)

export const UserContextProvider = props => {
  const [token, setToken] = useState(localStorage.getItem('token'))

  useEffect(() => {
    // Automatically set/unset default axios headers, localStorage token, query for current user
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      localStorage.setItem('token', token)
    } else {
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem('token')
    }
  }, [token])

  const fetchCurrentUser = async () => {
    if (token) {
      try {
        const { data } = await axios.get(`${API_URL}/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return data
      } catch (err) {
        if (err?.response?.status === 401) {
          // unauthorized request, remove token
          setToken('')
        }
        return null
      }
    } else return null
  }

  const loginHandler = async (email, password) => {
    try {
      const res = await axios.post(`${API_URL}/login`, { email, password }, { headers: {} })

      localStorage.setItem('token', res.data.token)
      setToken(res.data.token)

      return res.data
    } catch (err) {
      return err.response.data
    }
  }

  const logoutHandler = () => {
    setToken('')
    localStorage.removeItem('token')
    delete axios.defaults.headers.common['Authorization']
  }

  const userContextValues = {
    token,
    fetchCurrentUser,
    loginHandler,
    logoutHandler,
  }

  return <UserContext.Provider value={userContextValues}>{props.children}</UserContext.Provider>
}

import {
  CaretDownFilled,
  CaretUpFilled,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Button, Empty, message, Modal, Spin } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { DatePicker, Input, Select } from 'formik-antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'

import CampaignsListItem from './CampaignsListItem'
import affiliate from '../../../../assets/images/affiliate.svg'
import bespoke from '../../../../assets/images/bespoke.svg'
import giveaway from '../../../../assets/images/giveaway.svg'
import instagram from '../../../../assets/images/instagram-color-square.svg'
import shoutout from '../../../../assets/images/shoutout.svg'
import tiktok from '../../../../assets/images/tiktok-color-square.svg'
import ugc from '../../../../assets/images/ugc.svg'
import youtube from '../../../../assets/images/youtube-color-square.svg'
import { API_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import FormItem from '../../../utility/FormItem'

const { Option } = Select

const CampaignsList = () => {
  // #region Constants
  const history = useHistory()
  const [campaigns, setCampaigns] = useState([])
  const [admins, setAdmins] = useState([])
  const [currentSortItem, setCurrentSortItem] = useState('date-desc')
  const [newCampaignVisible, setNewCampaignVisible] = useState(false)
  const [weeks, setWeeks] = useState(false)
  const [formData, setFormData] = useState({ sort: currentSortItem })
  const [allBrands, setAllBrands] = useState([])
  const [fetchingBrands, setFetchingBrands] = useState(true)
  const submitRef = useRef(0)
  const scrollRef = useRef()
  const brandFetch = useRef(0)
  const { fetchCurrentUser } = useContext(UserContext)
  const specialists = admins.filter(admin => admin._count.assignedBrands)
  // #endregion Constants

  // #region Queries
  const { data: currentUser } = useQuery('current-user', fetchCurrentUser)
  const selectedSpecialist = specialists.find(specialist => specialist.id === currentUser.id)?.id

  const fetchCampaigns = async ({ pageParam = 1 }) => {
    const { data } = await axios.get(`${API_URL}/admin/campaigns/${pageParam}`, {
      params: formData,
    })
    return data
  }

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetchingMore, status } =
    useInfiniteQuery(['campaigns', formData], fetchCampaigns, {
      getNextPageParam: lastPage => lastPage.nextPage,
    })
  // #endregion Queries

  // #region Effects
  useEffect(() => {
    const array = data?.pages.flatMap(page => page.campaigns) || []
    setCampaigns(array)
  }, [data, status])

  useEffect(() => {
    fetchAdmins()
    fetchBrands()
  }, [])
  // #endregion Effects

  // #region Functions
  const fetchAdmins = async () => {
    try {
      const res = await axios.get(`${API_URL}/users`, {
        params: {
          role: 'administrator',
        },
      })
      setAdmins(res.data.users)
    } catch (err) {
      setAdmins([])
    }
  }

  const fetchBrands = async () => {
    try {
      const res = await axios.get(`${API_URL}/brands`, {
        params: { sort: 'campaigns-desc' },
      })
      setAllBrands(res.data.brands)
      setFetchingBrands(false)
    } catch (err) {
      setAllBrands([])
    }
  }

  const handleSearchBrands = async search => {
    brandFetch.current++
    const fetchId = brandFetch.current
    setFetchingBrands(true)
    try {
      const res = await axios.get(`${API_URL}/brands`, {
        params: { search, sort: 'campaigns-desc' },
      })
      if (brandFetch.current === fetchId) {
        setAllBrands(res.data.brands)
        setFetchingBrands(false)
      }
    } catch (err) {
      if (brandFetch.current === fetchId) {
        setAllBrands([])
        setFetchingBrands(false)
      }
    }
  }

  const sortBy = sortItem => {
    const updatedSortItem =
      currentSortItem === `${sortItem}-desc` ? `${sortItem}-asc` : `${sortItem}-desc`
    setCurrentSortItem(updatedSortItem)
    setFormData({ ...formData, sort: updatedSortItem })
  }

  const handleSearch = data => {
    submitRef.current++
    const thisSubmit = submitRef.current
    setTimeout(() => {
      if (thisSubmit === submitRef.current) {
        setFormData(data)
      }
    }, 300)
  }

  const handleScroll = () => {
    const { scrollTop, offsetHeight, scrollHeight } = scrollRef.current
    const actualDistance = scrollHeight - (scrollTop + offsetHeight)
    if (actualDistance < 400 && !isFetchingMore && hasNextPage) {
      fetchNextPage()
    }
  }
  // #endregion Functions

  return (
    <Wrapper>
      <Formik onSubmit={handleSearch} initialValues={formData}>
        {({ setValues, submitForm }) => {
          return (
            <>
              <div className='header-wrapper'>
                <h1>Campaigns</h1>
                {status === 'success' ? (
                  <span className='num-results'>
                    {campaigns?.length} / {data?.pages[0]?.totalResults?.toLocaleString() || 0}{' '}
                    results
                  </span>
                ) : (
                  <LoadingOutlined spin />
                )}
                <div className='header-buttons'>
                  {selectedSpecialist && (
                    <Button
                      type='secondary'
                      onClick={() => {
                        setValues(prev => ({ specialistId: selectedSpecialist, ...prev }))
                        submitForm()
                      }}>
                      My Campaigns
                    </Button>
                  )}
                  <Button
                    icon={<PlusOutlined />}
                    type='primary'
                    onClick={() => setNewCampaignVisible(true)}>
                    New Campaign
                  </Button>
                </div>
              </div>

              <Form className='filters'>
                <Input
                  name='search'
                  placeholder='Search campaigns'
                  prefix={<SearchOutlined />}
                  onChange={submitForm}
                  allowClear
                  style={{ width: 'auto' }}
                />
                <Select
                  name='brandId'
                  loading={fetchingBrands}
                  placeholder='Brand'
                  onChange={submitForm}
                  showSearch
                  filterOption={false}
                  onSearch={val => handleSearchBrands(val)}
                  allowClear>
                  {allBrands
                    ?.sort(
                      (a, b) =>
                        b.campaigns.filter(c => c.status === 'publish').length -
                        a.campaigns.filter(c => c.status === 'publish').length
                    )
                    .map(item => (
                      <Select.Option label={item.name} key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
                <Select
                  name='status'
                  placeholder='Status'
                  onChange={submitForm}
                  showArrow
                  allowClear>
                  <Option value='publish'>Active</Option>
                  <Option value='pending'>Pending</Option>
                  <Option value='draft'>Draft</Option>
                  <Option value='paused'>Paused</Option>
                </Select>
                <Select
                  name='socialChannel'
                  placeholder='Channel'
                  multiple
                  onChange={submitForm}
                  allowClear>
                  <Option value='instagram'>
                    <img src={instagram} alt='' style={{ height: '20px', marginRight: '8px' }} />
                    Instagram
                  </Option>
                  <Option value='tiktok'>
                    <img src={tiktok} alt='' style={{ height: '20px', marginRight: '8px' }} />
                    TikTok
                  </Option>
                  <Option value='youtube'>
                    <img src={youtube} alt='' style={{ height: '20px', marginRight: '8px' }} />
                    YouTube
                  </Option>
                </Select>
                <Select
                  name='strategy'
                  placeholder='Strategy'
                  multiple
                  onChange={submitForm}
                  allowClear>
                  <Option value='ugc'>
                    <img src={ugc} alt='' style={{ height: '20px', marginRight: '8px' }} />
                    UGC
                  </Option>
                  <Option value='shoutout'>
                    <img src={shoutout} alt='' style={{ height: '20px', marginRight: '8px' }} />
                    ShoutOut
                  </Option>
                  <Option value='giveaway'>
                    <img src={giveaway} alt='' style={{ height: '20px', marginRight: '8px' }} />
                    Giveaway
                  </Option>
                  <Option value='bespoke'>
                    <img src={bespoke} alt='' style={{ height: '20px', marginRight: '8px' }} />
                    Bespoke
                  </Option>
                  <Option value='affiliate'>
                    <img src={affiliate} alt='' style={{ height: '20px', marginRight: '8px' }} />
                    Affiliate
                  </Option>
                </Select>
                <Select
                  placeholder='Specialist'
                  name='specialistId'
                  onChange={submitForm}
                  loading={!admins.length}
                  allowClear>
                  {specialists.map(specialist => (
                    <Option
                      key={specialist.id}
                      value={
                        specialist.id
                      }>{`${specialist.firstName} ${specialist.lastName}`}</Option>
                  ))}
                </Select>
                <DatePicker
                  onChange={submitForm}
                  name='publishedFrom'
                  placeholder='Published From'
                  format='MMMM D, Y'
                />
                <DatePicker
                  onChange={submitForm}
                  name='publishedTo'
                  placeholder='Published To'
                  format='MMMM D, Y'
                />
              </Form>
            </>
          )
        }}
      </Formik>

      <Modal
        title='New Campaign'
        footer={null}
        onCancel={() => setNewCampaignVisible(false)}
        open={newCampaignVisible}>
        <Formik
          onSubmit={(data, { setSubmitting }) => {
            axios
              .post(`${API_URL}/campaign`, data)
              .then(res => {
                const { campaignId } = res.data
                history.push(`/campaign/${campaignId}`)
              })
              .catch(err => {
                message.error(err.response?.data?.err || 'An unknown error occurred')
              })
              .finally(() => {
                setSubmitting(false)
              })
          }}
          initialValues={{
            title: '',
            brandId: '',
          }}
          validationSchema={yup.object().shape({
            title: yup.string().required('Required'),
            brandId: yup.string().required('Required'),
          })}>
          {({ isSubmitting }) => (
            <Form>
              <FormItem label='Title' name='title'>
                <Input name='title' />
              </FormItem>
              <FormItem label='Brand' name='brandId'>
                <Select
                  style={{ width: '100%' }}
                  name='brandId'
                  loading={fetchingBrands}
                  placeholder='Search'
                  showSearch
                  filterOption={false}
                  onSearch={val => handleSearchBrands(val)}>
                  {allBrands.map(item => (
                    <Select.Option label={item.name} key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
              <Button loading={isSubmitting} htmlType='submit' type='primary'>
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>

      <div className='list-inner' onScroll={handleScroll} ref={scrollRef}>
        <div className='list-header'>
          <div className='thumbnail' />
          <div
            className='campaign sortable'
            onClick={() => sortBy('title')}
            onKeyDown={() => sortBy('title')}
            tabIndex={0}
            role='button'>
            Title
            <div className='sort-arrows'>
              <span className={`sort-arrow ${currentSortItem === 'title-desc' && 'current'}`}>
                <CaretUpFilled />
              </span>
              <span className={`sort-arrow ${currentSortItem === 'title-asc' && 'current'}`}>
                <CaretDownFilled />
              </span>
            </div>
          </div>
          <div className='brand'>Brand</div>
          <div className='status'>Status</div>
          <div className='channel'>Channel(s)</div>
          <div className='strategy'>Strategy</div>
          <div
            className='date sortable'
            onClick={() => sortBy('date')}
            onKeyDown={() => sortBy('date')}
            tabIndex={0}
            role='button'>
            Created
            <div className='sort-arrows'>
              <span className={`sort-arrow ${currentSortItem === 'date-asc' && 'current'}`}>
                <CaretUpFilled />
              </span>
              <span className={`sort-arrow ${currentSortItem === 'date-desc' && 'current'}`}>
                <CaretDownFilled />
              </span>
            </div>
          </div>
          <div
            className='date sortable'
            onClick={() => sortBy('publishDate')}
            onKeyDown={() => sortBy('publishDate')}
            tabIndex={0}
            role='button'>
            Published
            <div className='sort-arrows'>
              <span className={`sort-arrow ${currentSortItem === 'publishDate-asc' && 'current'}`}>
                <CaretUpFilled />
              </span>
              <span className={`sort-arrow ${currentSortItem === 'publishDate-desc' && 'current'}`}>
                <CaretDownFilled />
              </span>
            </div>
          </div>
          {weeks && (
            <>
              <div className='opt-ins'>
                Opt-Ins
                <br />
                {weeks[0].mon}
                <br />
                {weeks[0].sun}
              </div>
              <div className='opt-ins'>
                Opt-Ins
                <br />
                {weeks[1].mon}
                <br />
                {weeks[1].sun}
              </div>
              <div className='opt-ins'>
                Opt-Ins
                <br />
                {weeks[2].mon}
                <br />
                {weeks[2].sun}
              </div>
            </>
          )}
        </div>
        {status === 'success' &&
          (campaigns?.length ? (
            <>
              {campaigns?.map((campaign, i) => (
                <CampaignsListItem key={i} campaign={campaign} weeks={weeks} setWeeks={setWeeks} />
              ))}
              {isFetchingNextPage && (
                <div className='loading'>
                  <Spin />
                </div>
              )}
            </>
          ) : (
            <div className='no-results'>
              <Empty description='No campaigns found.' />
            </div>
          ))}

        {status === 'loading' && (
          <div className='loading'>
            <Spin />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  background: #f4f4f8;
  width: 100%;
  min-height: 100%;
  max-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  padding: 20px;

  .header-wrapper {
    background: #fff;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 0 0;
    h1 {
      font-size: 20px;
      margin: 0;
    }
    .num-results {
      color: #999;
      font-size: 14px;
    }
    .header-buttons {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-left: auto;
    }
    .total {
      white-space: nowrap;
      font-size: 0.9rem;
      color: #666;
    }
  }

  .filters {
    background: #fff;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
    .ant-input {
      flex: 1;
      min-width: 250px;
      max-width: 600px;
    }
    .ant-select,
    .ant-picker,
    .ant-picker-range {
      flex: 1;
      min-width: 200px;
      max-width: 200px;
    }
  }

  .list-inner {
    background: #fff;
    flex: 1;
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 10px 10px;
    ${props => props.theme.scrollbar}

    .no-results {
      flex: 1;
      display: grid;
      place-content: center;
    }
  }

  .list-header,
  .list-item {
    width: max-content;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
      width: 200px;
      min-height: 50px;
      padding: 5px 10px;
      margin: 5px;
      display: flex;
      align-items: center;
      word-break: break-word;
      p {
        margin: 0;
      }
    }
    .thumbnail {
      height: 85px;
      width: 85px;
      font-size: 2rem;
      color: #ddd;
      justify-content: center;
      img {
        border-radius: 5px;
        max-width: 100%;
        aspect-ratio: 1;
      }
    }
    .id {
      width: 110px;
    }
    .campaign {
      width: 300px;
    }
    .user {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .email {
        font-size: 0.8rem;
        color: ${props => props.theme.crcoGrey};
      }
    }
    .email {
      width: 250px;
    }
    .country {
      width: 150px;
    }
    .status,
    .channel,
    .strategy,
    .role,
    .socials {
      justify-content: center;
      text-align: center;
      width: 100px;
    }
    .date,
    .opt-ins,
    .num-campaigns {
      justify-content: center;
      text-align: center;
      width: 120px;
    }
    .niche {
      width: 300px;
      justify-content: center;
    }
    .none {
      color: #ccc;
    }
  }

  .list-header {
    background-color: #fff;
    font-size: 12px;
    position: sticky;
    top: 0;
    box-shadow: 0px 10px 10px -10px #00000036;
    z-index: 1;
    text-transform: uppercase;
    color: ${props => props.theme.crcoGrey};
    z-index: 1;
    .thumbnail {
      height: auto;
    }
    .sortable {
      border-radius: 10px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        background: #f6f6f6;
      }
      .sort-arrows {
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        font-size: 10px;
      }
      .sort-arrow {
        line-height: 0.5;
        color: #ccc;
        transition: 0.2s ease-in-out;
        &.current {
          color: #666;
        }
      }
    }
  }

  .list-item {
    color: #23262f;
    font-size: 14px;
    transition: 0.2s ease-in-out;
    &:not(:last-child) {
      border-bottom: 1px solid #ebeef3;
    }
    &:hover {
      background-color: #f6f6f6;
    }
    .channel,
    .strategy {
      gap: 5px;
      img {
        height: 20px;
        width: 20px;
      }
    }
    .status {
      font-size: 0.9rem;
      text-transform: capitalize;
      &.publish,
      &.activated {
        color: ${props => props.theme.crcoTechBlue};
      }
      &.completed {
        color: #22b162;
      }
      &.pending {
        color: #ff7500;
      }
      &.paused {
        color: #999;
      }
      &.draft {
        color: #ccc;
      }
      &.cancelled {
        color: red;
      }
    }
    .role {
      text-transform: capitalize;
    }
    .date {
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      gap: 5px;
      white-space: nowrap;
    }
    .num-campaigns,
    .opt-ins {
      color: ${props => props.theme.crcoTechBlue};
      &.none {
        color: #ccc;
      }
    }
    .socials {
      gap: 3px;
      .social-icon {
        height: 20px;
        width: 20px;
      }
    }
    .niche {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .loading {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export default CampaignsList

import { Alert, Button, Drawer, message } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input } from 'formik-antd'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'

import { API_URL } from '../../../constants'
import FormItem from '../../utility/FormItem'

const Utility = () => {
  const [socialProfile, setSocialProfile] = useState(null)
  const [engagementBreakdown, setEngagementBreakdown] = useState(null)
  const [engagementDrawerVisible, setEngagementDrawerVisible] = useState(false)

  const engagementCalculationSchema = yup.object().shape({
    userId: yup.string().required('Required'),
    accountId: yup.string().required('Required'),
  })

  useEffect(() => {
    if (socialProfile?.extraData?.engagementBreakdown) {
      setEngagementBreakdown(socialProfile.extraData.engagementBreakdown)
    }
  }, [setEngagementBreakdown, socialProfile])

  const handleEngagementCalculation = async values => {
    try {
      const res = await axios.post(
        `${API_URL}/engagement-calculation/${values.accountId}/${values.userId}`
      )

      setSocialProfile(res.data)
    } catch (err) {
      message.error(err.response.data.message)
    }
  }

  return (
    <Wrapper>
      <h1>Utility</h1>

      <Formik
        initialValues={{ userId: '', accountId: '' }}
        validationSchema={engagementCalculationSchema}
        onSubmit={handleEngagementCalculation}>
        {({ isSubmitting, submitForm }) => (
          <Form>
            <h2>Manually Update Engagement</h2>
            <p className='info-text'>
              This will update the engagement rate for the given user&apos;s social profile and
              provide a breakdown on how it was calculated.
            </p>
            <FormItem name='userId' label='User ID'>
              <Input name='userId' />
            </FormItem>
            <FormItem name='accountId' label='Phyllo Social Account ID'>
              <Input name='accountId' />
            </FormItem>
            <Button type='primary' loading={isSubmitting} onClick={submitForm}>
              Submit
            </Button>
            {!!socialProfile && (
              <div className='success'>
                <Alert
                  message='Engagement Updated'
                  type='success'
                  showIcon
                  action={
                    <Button type='secondary' onClick={() => setEngagementDrawerVisible(true)}>
                      View Breakdown
                    </Button>
                  }
                />
              </div>
            )}
          </Form>
        )}
      </Formik>

      <Drawer
        title='Engagement Breakdown'
        open={engagementDrawerVisible}
        onClose={() => setEngagementDrawerVisible(false)}
        destroyOnClose={true}>
        <EngagementBreakdown>
          {!!socialProfile && !!engagementBreakdown && (
            <>
              <h3>How does it work?</h3>
              <p>
                In order to determine engagement rate, we look at recent content within the past
                year or 90 days preceding initial social account connection, whichever period is
                shorter. We also exclude the last 30 days as newer content may have lower
                engagement.
              </p>
              <p>
                The percentage is calculated by summing up average engagements on each content, then
                dividing by follower count and multiplying by 100.
              </p>

              <p className='table-row'>
                <span className='label'>Social Profile ID</span> #{socialProfile.id}
              </p>
              <p className='table-row'>
                <span className='label'>Platform</span>{' '}
                {socialProfile.phylloData?.work_platform?.name || 'Unknown'}
              </p>
              <p className='table-row'>
                <span className='label'>Username</span>{' '}
                <a href={socialProfile.url} target='_blank' rel='noopener noreferrer'>
                  {socialProfile.username}
                </a>
              </p>
              <p className='table-row'>
                <span className='label'>Followers</span> {engagementBreakdown.followerCount}
              </p>
              <p className='table-row'>
                <span className='label'>Engagement Rate</span> {engagementBreakdown.engagement}
              </p>

              <h3>Engagements</h3>
              <p className='table-row'>
                <span className='label'>Total Likes</span> {engagementBreakdown.totalLikes}
              </p>
              <p className='table-row'>
                <span className='label'>Total Dislikes</span> {engagementBreakdown.totalDislikes}
              </p>
              <p className='table-row'>
                <span className='label'>Total Comments</span> {engagementBreakdown.totalComments}
              </p>
              <p className='table-row'>
                <span className='label'>Total Saves</span> {engagementBreakdown.totalSaves}
              </p>
              <p className='table-row'>
                <span className='label'>Total Shares</span> {engagementBreakdown.totalShares}
              </p>
              <p className='table-row'>
                <span className='label'>Average Likes</span> {engagementBreakdown.avgLikes}
              </p>
              <p className='table-row'>
                <span className='label'>Average Dislikes</span> {engagementBreakdown.avgDislikes}
              </p>
              <p className='table-row'>
                <span className='label'>Average Comments</span> {engagementBreakdown.avgComments}
              </p>
              <p className='table-row'>
                <span className='label'>Average Saves</span> {engagementBreakdown.avgSaves}
              </p>
              <p className='table-row'>
                <span className='label'>Average Shares</span> {engagementBreakdown.avgShares}
              </p>

              <h3>Content</h3>
              <p className='table-row'>
                <span className='label'>Date Range</span>{' '}
                {moment(engagementBreakdown.fromDate).format('ll')} -{' '}
                {moment(engagementBreakdown.toDate).format('ll')}
              </p>
              <p className='table-row'>
                <span className='label'>Content Count</span> {engagementBreakdown.contentCount}
              </p>
              {engagementBreakdown.content.map((content, index) => (
                <div key={index} className='content-card'>
                  <a href={content.url} target='_blank' rel='noopener noreferrer'>
                    <div className='details'>
                      {socialProfile?.instagramId && content.persistent_thumbnail_url && (
                        <img src={content.persistent_thumbnail_url} alt='' />
                      )}
                      <div className='text'>
                        <p className='date'>{moment(content.published_at).format('ll')}</p>
                        <p className='caption'>{content.title}</p>
                        <p className='type'>
                          {content.type} / {content.format}
                        </p>
                      </div>
                    </div>
                    <div className='stats'>
                      <p className='stat'>
                        <span className='label'>Likes</span> {content.engagement.like_count || '-'}
                      </p>
                      <p className='stat'>
                        <span className='label'>Dislikes</span>{' '}
                        {content.engagement.dislike_count || '-'}
                      </p>
                      <p className='stat'>
                        <span className='label'>Comments</span>{' '}
                        {content.engagement.comment_count || '-'}
                      </p>
                      <p className='stat'>
                        <span className='label'>Saves</span> {content.engagement.save_count || '-'}
                      </p>
                      <p className='stat'>
                        <span className='label'>Shares</span>{' '}
                        {content.engagement.share_count || '-'}
                      </p>
                    </div>
                  </a>
                </div>
              ))}
            </>
          )}
        </EngagementBreakdown>
      </Drawer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 18px;
  }
  form {
    background: #f6f6f6;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
  }
  .form-row {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .info-text {
    font-size: 14px;
    color: #999;
  }
  .success {
    margin-top: 20px;
  }
`

const EngagementBreakdown = styled.div`
  h3 {
    font-size: 18px;
    color: ${props => props.theme.crcoTechBlue};
    &:not(:first-child) {
      margin-top: 30px;
    }
  }
  .table-row {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    padding: 5px 0;
    margin: 0;
  }
  .label {
    font-weight: bold;
    color: #999;
  }
  .content-card {
    border: 1px solid #e6e6e6;
    padding: 10px;
    border-radius: 10px;
    margin-top: 15px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    a {
      color: ${props => props.theme.crcoMidnight};
    }
    .details {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      img {
        width: 100px;
        aspect-ratio: 1;
        object-fit: cover;
        border-radius: 5px;
      }
      .text {
        max-width: 60%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        p {
          margin: 0;
        }
      }
      .date,
      .type {
        font-size: 12px;
        color: #999;
      }
      .caption {
        font-family: 'Campton-Light', sans-serif;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .stat {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #e6e6e6;
      padding: 3px 0;
      margin: 0;
    }
  }
`

export default Utility

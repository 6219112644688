import { PlusOutlined } from '@ant-design/icons'
import { Button, message, Drawer, Empty } from 'antd'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'

import CampaignStep from './CampaignStep'
import StepList from './StepList'
import { API_URL } from '../../../../../constants'

const CampaignSteps = ({ campaignId, brandId, campaignSteps }) => {
  const queryClient = useQueryClient()
  const [addStepOpen, setAddStepOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [orderedSteps, setOrderedSteps] = useState(
    campaignSteps.filter(step => step.placement).sort((a, b) => a.placement - b.placement)
  )

  useEffect(() => {
    setOrderedSteps(
      campaignSteps.filter(step => step.placement).sort((a, b) => a.placement - b.placement)
    )
  }, [campaignSteps])

  const saveSteps = async stepsData => {
    setLoading(true)

    try {
      await axios.put(`${API_URL}/brand/${brandId}/campaign/${campaignId}/steps`, {
        steps: stepsData,
      })
      await queryClient.invalidateQueries('campaign')
      message.success({ content: 'Steps Updated', maxCount: 1 })
    } catch (error) {
      message.warning('Something went wrong updating the steps')
    } finally {
      setLoading(false)
    }
  }

  const addStep = async step => {
    const placedSteps = campaignSteps.map(step => step.placement).filter(i => !!i)
    const placement = placedSteps.length ? Math.max(...placedSteps) + 1 : 1
    const structuredSteps = [...campaignSteps, { placement, ...step }]
    await saveSteps(structuredSteps)
  }

  const swapSteps = async (step, move) => {
    const updatedSteps = campaignSteps.map(thisStep => {
      const thisPlacement = thisStep.placement,
        currentPlacement = step.placement
      if (thisStep.id === step.id) {
        return {
          ...thisStep,
          placement: thisPlacement + move,
        }
      } else if (thisPlacement === currentPlacement + move) {
        return {
          ...thisStep,
          placement: currentPlacement,
        }
      } else {
        return thisStep
      }
    })
    await saveSteps(updatedSteps)
  }

  return (
    <Wrapper>
      <div className='section-wrapper steps-section'>
        {orderedSteps?.length ? (
          orderedSteps.map((step, i) => (
            <CampaignStep
              key={i}
              index={i}
              brandId={brandId}
              step={step}
              campaignSteps={campaignSteps}
              swapSteps={move => swapSteps(step, move)}
              addStep={addStep}
              saveSteps={saveSteps}
              loading={loading}
              close={() => setAddStepOpen(false)}
            />
          ))
        ) : (
          <Empty description='No steps added yet' />
        )}

        <div className='add-step-btn'>
          <Button
            loading={loading}
            onClick={() => setAddStepOpen(true)}
            icon={<PlusOutlined />}
            type='primary'>
            {loading ? 'Saving' : 'Add Step'}
          </Button>
        </div>
      </div>

      <Drawer
        open={addStepOpen}
        title='Add Step'
        onClose={() => setAddStepOpen(false)}
        destroyOnClose
        width={550}
        footer={null}>
        <StepList close={() => setAddStepOpen(false)} addStep={addStep} brandId={brandId} />
      </Drawer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 20px 0;
  .add-step-btn {
    display: flex;
    justify-content: center;
    margin: 20px auto 0 auto;
  }
`

export default CampaignSteps

import React, { useContext } from 'react'
import './index.css'
import 'antd/dist/antd.css'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import Dashboard from './components/layout/dashboard/Dashboard'
import { Header } from './components/layout/Header'
import { Login } from './components/layout/Login'
import { Logout } from './components/layout/Logout'
import { MobileMenu } from './components/layout/MobileMenu'
import { UserContext } from './contexts/UserContext'
import { theme } from './GlobalStyles'

export const App = () => {
  const { token } = useContext(UserContext)

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Header />
        <MobileMenu />

        <Switch>
          <Route path='/login'>
            <Login />
          </Route>

          <Route path='/logout'>
            <Logout />
          </Route>

          {token ? <Dashboard /> : <Redirect to='/login' />}
        </Switch>
      </Router>
      <ReactQueryDevtools defaultIsOpen={false} position='bottom-right' />
    </ThemeProvider>
  )
}

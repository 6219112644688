import { HeartFilled } from '@ant-design/icons'
import { Button, message, Modal, Tooltip } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { DatePicker, Input, Rate } from 'formik-antd'
import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'

import { API_URL } from '../../../../constants'
import FormItem from '../../../utility/FormItem'

const OptInSteps = ({ optIn }) => {
  const optInId = optIn.id
  const { campaignSteps, currentStepId, rating } = optIn
  const queryClient = new useQueryClient()
  const [stepDetailsVisible, setStepDetailsVisible] = useState(false)
  const [copied, setCopied] = useState(false)

  const updateStep = async (values, campaignStepId) => {
    const data = {
      ...values,
      rating: {
        content: values.content,
        creativity: values.creativity,
        communication: values.communication,
        overall: values.overall,
      },
    }

    try {
      await axios.post(`${API_URL}/admin-access/opt-in/${optInId}/step/${campaignStepId}`, data)
      await queryClient.invalidateQueries(['opt-in', optInId])
      message.success('Step Updated')
    } catch (err) {
      //Show the error message for 5 seconds.
      message.error(
        `Error updating step. ${err?.response?.data?.err || 'An unknown error occurred.'}`,
        5
      )
    }
  }

  return (
    <Wrapper>
      {campaignSteps?.map(campaignStep => {
        return (
          <Formik
            key={campaignStep.id}
            initialValues={{
              completed: campaignStep.optInStep?.completed,
              url: campaignStep.optInStep?.data?.shoutoutData?.url,

              // opt-in rating
              content: rating?.content,
              creativity: rating?.creativity,
              communication: rating?.communication,
              overall: rating?.overall,
            }}
            onSubmit={values => updateStep(values, campaignStep.id)}>
            {({ values, setValues, setFieldValue, submitForm, isSubmitting }) => (
              <Form>
                <div className={`step ${campaignStep.id === currentStepId ? 'current' : ''}`}>
                  <span className='number'>{campaignStep.placement}</span>
                  <span className='title'>
                    {campaignStep.step ? campaignStep.step.title : campaignStep.customStepTitle}
                  </span>
                  <DatePicker
                    onChange={submitForm}
                    name='completed'
                    placeholder='Not Completed'
                    style={{ minWidth: '160px', marginLeft: 'auto' }}
                    format='MMMM D, Y'
                  />
                  <div className='buttons'>
                    <Button
                      className='current-step-btn'
                      loading={isSubmitting}
                      onClick={() => {
                        if (campaignStep.id !== currentStepId) {
                          setValues(prev => ({ ...prev, currentStep: true }))
                          submitForm()
                        }
                      }}
                      type={campaignStep.id === currentStepId ? 'primary' : 'secondary'}>
                      {campaignStep.id === currentStepId ? 'Current Step' : 'Set Current'}
                    </Button>
                    {campaignStep.optInStep && (
                      <Button
                        disabled={!campaignStep.optInStep}
                        type='link'
                        onClick={() => setStepDetailsVisible(campaignStep.id)}>
                        View Details
                      </Button>
                    )}
                  </div>
                </div>

                <Modal
                  title='Step Details'
                  open={stepDetailsVisible === campaignStep.id}
                  footer={null}
                  onCancel={() => setStepDetailsVisible(false)}
                  destroyOnClose>
                  <StepDetails>
                    <div className='ids'>
                      <div className='id-wrapper'>
                        Campaign Step
                        <CopyToClipboard
                          text={campaignStep.id}
                          onCopy={() => {
                            setCopied(campaignStep.id)
                            setTimeout(() => setCopied(false), 1000)
                          }}>
                          <Tooltip open={copied === campaignStep.id} title='Copied'>
                            <span className='copy-id'> #{campaignStep.id}</span>
                          </Tooltip>
                        </CopyToClipboard>
                      </div>
                      {campaignStep.optInStep && (
                        <div className='id-wrapper'>
                          Opt-In Step
                          <CopyToClipboard
                            text={campaignStep.optInStep.id}
                            onCopy={() => {
                              setCopied(campaignStep.optInStep.id)
                              setTimeout(() => setCopied(false), 1000)
                            }}>
                            <Tooltip open={copied === campaignStep.optInStep.id} title='Copied'>
                              <span className='copy-id'> #{campaignStep.optInStep.id}</span>
                            </Tooltip>
                          </CopyToClipboard>
                        </div>
                      )}
                    </div>

                    {campaignStep.stepId === 1 && (
                      <div className='details-section'>
                        <h3>Social Post URL</h3>
                        <div className='input-btn-wrapper'>
                          <Input
                            defaultValue={campaignStep.optInStep?.data?.shoutoutData?.url}
                            name='url'
                            onChange={e => setFieldValue('url', e.target.value)}
                          />
                          <Button loading={isSubmitting} type='primary' onClick={submitForm}>
                            Update
                          </Button>
                        </div>
                      </div>
                    )}

                    {campaignStep.stepId === 1004 && (
                      <div className='details-section'>
                        <div className='complete'>
                          <h3>Rating</h3>
                          <FormItem
                            name='content'
                            label='Content Quality: '
                            sublabel={`How would you rate the overall quality of the influencer's content?`}
                            size='small'>
                            <Rate className='rate' character={<HeartFilled />} name='content' />
                          </FormItem>

                          <FormItem
                            name='creativity'
                            label='Creativity: '
                            sublabel={`How would you rate the creativity of the influencer's approach to your campaign?`}
                            size='small'>
                            <Rate className='rate' character={<HeartFilled />} name='creativity' />
                          </FormItem>

                          <FormItem
                            name='communication'
                            label='Communication: '
                            sublabel={`How effective was the influencer's communication throughout the partnership?`}
                            size='small'>
                            <Rate
                              className='rate'
                              character={<HeartFilled />}
                              name='communication'
                            />
                          </FormItem>

                          <FormItem
                            name='overall'
                            label='Overall Satisfaction: '
                            sublabel={`How satisfied are you with the partnership with the influencer?`}
                            size='small'>
                            <Rate className='rate' character={<HeartFilled />} name='overall' />
                          </FormItem>

                          {(values.content !== rating?.content ||
                            values.creativity !== rating?.creativity ||
                            values.communication !== rating?.communication ||
                            values.overall !== rating?.overall) && (
                            <Button
                              htmlType='submit'
                              loading={isSubmitting}
                              type='primary'
                              onClick={submitForm}>
                              Update Rating
                            </Button>
                          )}
                        </div>
                      </div>
                    )}

                    {!!campaignStep.optInStep?.data?.length && (
                      <div className='details-section'>
                        <pre>{JSON.stringify(campaignStep.optInStep?.data, null, 2)}</pre>
                      </div>
                    )}
                  </StepDetails>
                </Modal>
              </Form>
            )}
          </Formik>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .step {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    .number {
      font-size: 1rem;
      background-color: #ecf6ff;
      color: ${props => props.theme.crcoTechBlue};
      border-radius: 5px;
      padding: 2px 8px;
      font-weight: bold;
      margin-right: 8px;
    }
    .title {
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.current {
      color: ${props => props.theme.crcoTechBlue};
      .number {
        background-color: ${props => props.theme.crcoTechBlue};
        color: #fff;
      }
      .set-current {
        cursor: default;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 10px;
    button {
      width: 140px;
    }
  }
`

const StepDetails = styled.div`
  .id-wrapper {
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      margin: 0;
    }
  }
  .copy-id {
    cursor: pointer;
    color: ${props => props.theme.crcoGrey};
    transition: 0.2s ease-in-out;
    &:hover {
      color: ${props => props.theme.crcoMidnight};
    }
  }
  .details-section {
    border-top: 1px solid #e6e6e6;
    margin-top: 20px;
    padding-top: 20px;
  }
  .input-btn-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .rate {
    color: ${props => props.theme.crcoCoral};
  }
`

export default OptInSteps

// Helper function to capitalize the first letter of a string
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// Helper function to check if url comes from a video
export const isVideo = url => {
  try {
    const ext = new URL(url)?.pathname?.toLowerCase()
    return ext?.endsWith('mp4') || ext?.endsWith('mov')
  } catch {
    return false
  }
}
